import * as React from 'react';
import { useEffect } from 'react';
import { RouteComponentProps } from "@reach/router";
import { useMap } from "react-leaflet";
import L from "leaflet";
import { MAP_FEATURE_COLORS } from '../../constants';


interface MapLineHybridControlProps extends RouteComponentProps {}

const MapLineHybridControl: React.FC<MapLineHybridControlProps> = () => {
    const map = useMap();

    const createButtonControl = () => {
        let button: any;
        const Control = L.Control.extend({
            options: {},
            onAdd: (map: any) => {
                button = L.DomUtil.create("button", "customize-button");
                button.title = "Draw a hybrid path";
                button.innerHTML = '<img src="/extendPath.png" width="21" height="21" />';
        
                button.addEventListener("click", (e: any) => {
                    e.stopPropagation();
                    new L.Draw.Polyline(map, {
                        shapeOptions: {
                            color: MAP_FEATURE_COLORS.HYBRID,
                            weight: 4,
                            opacity: 0.5,
                            clickable: true
                        }
                    }).enable();
                });
        
                return button;
            }
        });
        return new Control({ position: "topright" });
    }

    useEffect(() => {
        const control = createButtonControl();
        control.addTo(map);
    }, []);

    return null;
}

export default MapLineHybridControl;