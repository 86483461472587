import * as React from 'react';
import { useState, useEffect } from 'react';
import { RouteComponentProps } from "@reach/router";
import { useDispatch, useSelector } from 'react-redux';
import { analysisDataSelector } from '../../redux/selectors/analysisDataSelector';
import { IState } from '../../redux/@types';
import {
    Box,
} from "@mui/material";

// Leaflet
import { LatLng } from 'leaflet';
import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    useMapEvent,
    useMap,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "react-leaflet-fullscreen/dist/styles.css";
import { formsActions } from "../../redux/actions/formsActions";


const markerIcon = typeof window !== 'undefined' && L.icon({
    iconUrl: "/markerIcon.svg",
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    // iconSize: [80, 80],
    // iconAnchor: [40, 80],
    popupAnchor: [0, -76]
})

interface SingleMarkerMapEventProps extends RouteComponentProps {
    dataKey: string
    questionText?: string
}

const SingleMarkerMapEvent: React.FC<SingleMarkerMapEventProps> = ({ questionText, dataKey }) => {
    const dispatch = useDispatch();
    const map = useMap();

    const currentAnalysis = useSelector((state: IState) => state.forms.currentAnalysis);
    const [position, sPosition] = useState<any>();


    const mapE = useMapEvent('click', (e) => {
        sPosition(e.latlng);
        dispatch(formsActions.updateFormResponse(dataKey, e.latlng, "map_answer", questionText));
    });

    useEffect(() => {
        //@ts-ignore
        if (currentAnalysis && currentAnalysis.response && currentAnalysis.response.Q4) {
            //@ts-ignore
            const existingPos = currentAnalysis.response.Q4.answer;
            const zoom = map.getZoom();
            const pos = { lat: existingPos[0][1], lng: existingPos[1][1] };
            map.setView(pos, zoom);
            sPosition(pos);
        }
    }, [currentAnalysis]);

    return (
        position && <Marker
            position={position}
            icon={markerIcon || undefined}
        /> || null
    );
}

export default SingleMarkerMapEvent;