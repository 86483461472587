import * as React from 'react';
import { useState, ChangeEvent, useEffect } from 'react';
import { RouteComponentProps } from "@reach/router";
import { useDispatch, useSelector } from 'react-redux';
import { analysisDataSelector } from '../../redux/selectors/analysisDataSelector';
import { IState } from '../../redux/@types';
import {
    Box,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import { handleInputChange } from "../../utils/formHandlers";
import { formsActions } from "../../redux/actions/formsActions";



interface MultipleChoiceInputProps extends RouteComponentProps {
    dataKey: string
    defaultValue: any
    questionText: string
    required: boolean
}

const MultipleChoiceInput: React.FC<MultipleChoiceInputProps> = ({
    defaultValue,
    dataKey,
    questionText,
    required,
}) => {
    const dispatch = useDispatch();

    const selectedValue = useSelector(analysisDataSelector(dataKey));
    const [value, setValue] = useState<any>(selectedValue || []);

    const onChange = (v: any) => {
        let selected = JSON.parse(JSON.stringify(value));
        const i = selected.indexOf(v[0]);
        if (i === -1) selected.push(v[0]);
        else selected.splice(i, 1);
        setValue(selected);
        dispatch(formsActions.updateFormResponse(dataKey, selected, "multiple_answer", questionText));
    }

    useEffect(() => {
        // dispatch(formsActions.updateFormResponse(dataKey, null, "multiple_answer", questionText));
    }, []);

    return (
        <Box>
            <FormGroup>
                {
                    defaultValue && Array.isArray(defaultValue) && defaultValue.map((v: string[]) => (
                        <FormControlLabel
                            key={`checkbox-${dataKey}-${v[0]}`}
                            value={v[0]}
                            control={<Checkbox />}
                            onChange={() => onChange(v)}
                            checked={!!value && !!v && Array.isArray(value) && value.indexOf(v[0]) !== -1}
                            label={v[1]}
                            sx={{ marginBottom: '10px' }}
                        />
                    ))
                }
            </FormGroup>
        </Box>
    );
}

export default MultipleChoiceInput;