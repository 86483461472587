import * as React from 'react';
import { useState, useRef, useEffect, useMemo } from 'react';
import { RouteComponentProps } from "@reach/router";
import { useMap } from "react-leaflet";
import L from "leaflet";
import { useDispatch, useSelector } from 'react-redux';
import { formsActions } from "../../redux/actions/formsActions";
import "leaflet-draw";
import "leaflet-linear-measurement/src/Leaflet.LinearMeasurement";
import "./MapRulerControl.css";


interface MapRulerControlProps extends RouteComponentProps {}

const MapRulerControl: React.FC<MapRulerControlProps> = ({}) => {
    const map = useMap();

    const createButtonControl = () => {
        // @ts-ignore
        const Ruler = L.Control.LinearMeasurement;
        const newRuler = new Ruler({
            unitSystem: 'metrics',
            color: '#FF0099',
            type: 'line',
            features: 'ruler',
        });

        newRuler.resetRulerOrg = newRuler.resetRuler;

        newRuler.initRuler = function () {
            var me = this,
                map = this._map;

            if (!this.mainLayer) {
                this.mainLayer = L.featureGroup();
                this.mainLayer.addTo(this._map);
            }

            map.touchZoom.disable();
            map.doubleClickZoom.disable();
            map.boxZoom.disable();
            map.keyboard.disable();

            if (map.tap) {
                map.tap.disable();
            }

            this.dblClickEventFn = function (e: any) {
                L.DomEvent.stop(e);
            };

            this.clickEventFn = function (e: any) {
                if (me.clickHandle) {
                    clearTimeout(me.clickHandle);
                    me.clickHandle = 0;

                    if (me.options.show_last_node) {
                        me.preClick(e);
                        me.getMouseClickHandler(e);
                    }

                    me.getDblClickHandler(e);
                } else {
                    me.preClick(e);
                    me.clickHandle = setTimeout(function () {
                        me.getMouseClickHandler(e);
                        me.clickHandle = 0;
                    }, me.clickSpeed);
                }
            };

            this.moveEventFn = function (e: any) {
                if (!me.clickHandle) {
                    me.getMouseMoveHandler(e);
                }
            };

            map.on('click', this.clickEventFn, this);
            map.on('mousemove', this.moveEventFn, this);
            this.resetRuler();
        };
        // ReWrite the reset Ruler Function
        newRuler.resetRuler = function (resetLayer: any) {
            // console.log("resetLayer: " + resetLayer);
            // var map = this._map;
            if (resetLayer) {
                //do nothing

                this._map.off('click', this.clickEventFn, this);
                this._map.off('mousemove', this.moveEventFn, this);

                this._map.touchZoom.enable();

                this._map.boxZoom.enable();

                this._map.keyboard.enable();

                if (this._map.tap) {
                    this._map.tap.enable();
                }

                newRuler.resetRulerOrg(false);

            } else {
                newRuler.resetRulerOrg(resetLayer);
            }
        };

        return newRuler;
    }

    useEffect(() => {
        const control = createButtonControl();
        control.addTo(map);
    }, []);

    return null;
}

export default MapRulerControl;