import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from "@reach/router";
import { useMap } from "react-leaflet";
import L from "leaflet";
import "./HeatLayer";


const zoomRadius = {
    16: 13,
    17: 15,
    18: 20,
    19: 35,
    20: 70,
    21: 150,
    22: 300,
}
const valueScale = {
    16: 1,
    17: 1,
    18: 0.6,
    19: 0.3,
    20: 0.15,
    21: 0.08,
    22: 0.04,
}
// const opacity = 0.75;

interface HeatmapProps extends RouteComponentProps {
    heatmapOn: boolean
    coords: any[]
}

const Heatmap: React.FC<HeatmapProps> = ({ heatmapOn, coords }) => {
    const map = useMap();

    const [zoom, setZoom] = useState<number>(map.getZoom());
    const [heat, setHeat] = useState<any>({});

    useEffect(() => {
        // console.log("scale", zoom, zoomRadius[zoom], valueScale[zoom]);
        // @ts-ignore
        if (heatmapOn) map.removeLayer(heat);

        // @ts-ignore
        // const scaleCoords = coords.map(coord => [coord[0], coord[1], coord[2] * (valueScale[zoom] || 1)]);
        const scaleCoords = coords.map(coord => [coord[0], coord[1], coord[2]]);

        // @ts-ignore
        // const heatLayer = L.heatLayer(scaleCoords, { radius: zoomRadius[zoom] || 10 });
        // const heatLayer = L.heatLayer(scaleCoords, { radius: 35 });
        const heatLayer = L.heatLayer(scaleCoords);

        // @ts-ignore
        if (heatmapOn) map.addLayer(heatLayer);

        setHeat(heatLayer);
        // return L.heatLayer(coords, { radius: zoomRadius[zoom] || 10 })
    }, [coords, zoom])

    useEffect(() => {
        // @ts-ignore
        if (heatmapOn) map.addLayer(heat);
        // @ts-ignore
        else map.removeLayer(heat);
    }, [heatmapOn])

    useEffect(() => {
        map.on('zoomend', (e) => {
            setZoom(map.getZoom());
        })
    }, [])

    return null;
}

export default Heatmap;