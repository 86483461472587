import * as React from 'react';
import { useMemo, useState, useRef, useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import { useSelector } from 'react-redux';
import { formsActions } from "../../redux/actions/formsActions";
import {
    Box,
} from "@mui/material";
// Leaflet
import {
    MapContainer,
    TileLayer,
    Marker,
    Polyline,
    Polygon,
    FeatureGroup,
    ScaleControl,
} from "react-leaflet";
import L from "leaflet";
import DrawEvents from './DrawEvents';
import MapLocateControl from "./MapLocateControl";
import MapMarkerControl from "./MapMarkerControl";
import MapLineControl from "./MapLineControl";
import MapLineHybridControl from "./MapLineHybridControl";
import MapBlockingControl from "./MapBlockingControl";
import MapPerimeterControl from './MapPerimeterControl';
import MapSourceControl from "./MapSourceControl";
import MapDeleteControl from './MapDeleteControl';
import MapRulerControl from './MapRulerControl';
import MapFullscreenControl from "./MapFullscreenControl";
import GeoSearch from "./GeoSearch";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css"
import { analysisDataResponseMapSelector } from '../../redux/selectors/analysisDataResponseMapSelector';
import { MAP_FEATURE_COLORS, MAX_ZOOM, MAX_NATIVE_ZOOM, MIN_ZOOM } from '../../constants';
import PolylineDecorator from './PolylineDecorator';
import 'leaflet-polylinedecorator'; // for L.Symbol


export const markerIcon = typeof window !== 'undefined' && L.icon({
    iconUrl: "/poi_marker1.svg",
    iconSize: [80, 80], // size of the icon
    iconAnchor: [40, 40], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -40] // point from which the popup should open relative to the iconAnchor
});

const arrow_blue = [
    {
        offset: 25,
        repeat: 50,
        symbol: typeof window !== 'undefined' && L.Symbol.arrowHead({
            pixelSize: 8,
            polygon: false,
            pathOptions: {
                opacity: 0.5,
                color: MAP_FEATURE_COLORS.MANUAL,
                stroke: true
            }
        }) || undefined
    }
];
const arrow_green = [
    {
        offset: 25,
        repeat: 50,
        symbol: typeof window !== 'undefined' && L.Symbol.arrowHead({
            pixelSize: 8,
            polygon: false,
            pathOptions: {
                opacity: 0.5,
                color: MAP_FEATURE_COLORS.HYBRID,
                stroke: true
            }
        }) || undefined
    }
];

const square_red = [
    {
        offset: 0,
        repeat: 50,
        length: 0,
        symbol: typeof window !== 'undefined' && L.Symbol.dash({
            pixelSize: 10,
            pathOptions: {
                opacity: 0.5,
                color: MAP_FEATURE_COLORS.PERIMETER,
                weight: 7,
            }
        }) || undefined
    }
];
const line_opacity = 0.5;

interface DrawableMapProps extends RouteComponentProps { }

const DrawableMap: React.FC<DrawableMapProps> = () => {
    const responseMapFeatures = useSelector(analysisDataResponseMapSelector);

    const [attribution, sAttribution] = useState<string>('&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors');
    const [url, sUrl] = useState<string>("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png");
    const decoratorIdsRef = useRef({});
    const featureGroupRef = useRef(null);
    const decoratorFeatureGroupRef = useRef(null);
    // const [center, sCenter] = useState<[number, number]>([-33.8688, 151.2093]); // Coordinates for Sydney, Australia

    const addDecoratorId = (decoratorId: number, polylineId: number) => {
        const newDecoratorIds = {
            ...decoratorIdsRef.current,
            [polylineId]: decoratorId
        }
        decoratorIdsRef.current = newDecoratorIds;
    }

    const getInitialCenter = (responseMapFeatures: any) => {
        if (!responseMapFeatures) return;
        if (
            responseMapFeatures.markers.length > 0
            && typeof responseMapFeatures.markers[0][0] === 'number'
            && typeof responseMapFeatures.markers[0][1] === 'number'
        ) return responseMapFeatures.markers[0]; // First marker
        if (
            responseMapFeatures.manualPaths.length > 0
            && typeof responseMapFeatures.manualPaths[0][0][0] === 'number'
            && typeof responseMapFeatures.manualPaths[0][0][1] === 'number'
        ) return responseMapFeatures.manualPaths[0][0]; // First manual path
        if (
            responseMapFeatures.hybridPaths.length > 0
            && typeof responseMapFeatures.hybridPaths[0][0][0] === 'number'
            && typeof responseMapFeatures.hybridPaths[0][0][1] === 'number'
        ) return responseMapFeatures.hybridPaths[0][0]; // First hybrid path
        if (
            responseMapFeatures.perimeters.length > 0
            && typeof responseMapFeatures.perimeters[0][0][0] === 'number'
            && typeof responseMapFeatures.perimeters[0][0][1] === 'number'
        ) return responseMapFeatures.perimeters[0][0]; // First perimeter path
        if (
            responseMapFeatures.obstacles.length > 0
            && typeof responseMapFeatures.obstacles[0][0][0] === 'number'
            && typeof responseMapFeatures.obstacles[0][0][1] === 'number'
        ) return responseMapFeatures.obstacles[0][0]; // First block
        return [-33.8688, 151.2093]; // Sydney, Australia
    }

    const [center, sCenter] = useState<[number, number]>(getInitialCenter(responseMapFeatures)); // Coordinates for Sydney, Australia

    return (
        <Box
            id="map"
            sx={{
                '& .leaflet-container': {
                    height: '500px',
                    width: '100%',
                },
                '.customize-button': {
                    width: '35px',
                    height: '35px',
                    lineHeight: '30px',
                    borderTopLeftRadius: '2px',
                    borderTopRightRadius: '2px',
                    padding: '5px',
                    backgroundColor: '#fff',
                    cursor: 'pointer',
                    border: '2px solid rgba(0,0,0,.2)',
                },
            }}
        >
            <MapContainer
                center={center || [-33.8688, 151.2093]}
                zoom={13}
                minZoom={MIN_ZOOM}
                maxZoom={MAX_ZOOM}
                scrollWheelZoom={true}
            >
                <TileLayer
                    attribution={attribution}
                    url={url}
                    minZoom={MIN_ZOOM}
                    maxZoom={MAX_ZOOM}
                    maxNativeZoom={MAX_NATIVE_ZOOM}
                />
                <MapFullscreenControl />
                <ScaleControl />
                <DrawEvents
                    featureGroupRef={featureGroupRef}
                    decoratorFeatureGroupRef={decoratorFeatureGroupRef}
                    decoratorIdsRef={decoratorIdsRef}
                />
                <MapRulerControl />
                <MapLocateControl />
                <GeoSearch />
                <MapSourceControl
                    sAttribution={sAttribution}
                    sUrl={sUrl}
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    iconSrc="/map.svg"
                    title="Map view"
                />
                <MapSourceControl
                    sAttribution={sAttribution}
                    sUrl={sUrl}
                    url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                    attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
                    iconSrc="/satellite.svg"
                    title="Satellite view"
                />
                <MapSourceControl
                    sAttribution={sAttribution}
                    sUrl={sUrl}
                    url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                    iconSrc="/black-map.svg"
                    title="Dark view"
                />
                <MapLineHybridControl />
                <MapLineControl />
                <MapBlockingControl />
                {/* <MapPerimeterControl /> */}
                <MapMarkerControl />
                <MapDeleteControl featureGroupRef={featureGroupRef} />
                <FeatureGroup ref={featureGroupRef}>
                {responseMapFeatures && responseMapFeatures.markers && responseMapFeatures.markers.map(
                    (coordinate: any) => <Marker key={`marker-${coordinate}`} position={coordinate} icon={markerIcon || undefined} />
                )}
                {responseMapFeatures && responseMapFeatures.manualPaths && responseMapFeatures.manualPaths.map(
                    (coordinates: any) =>
                        <PolylineDecorator
                            key={`manualpath-${coordinates}`}
                            positions={coordinates}
                            patterns={arrow_blue}
                            pathOptions={{ color: MAP_FEATURE_COLORS.MANUAL, opacity: line_opacity }}
                            featureGroupRef={featureGroupRef}
                            decoratorFeatureGroupRef={decoratorFeatureGroupRef}
                            addDecoratorId={addDecoratorId}
                        />
                )}
                {responseMapFeatures && responseMapFeatures.hybridPaths && responseMapFeatures.hybridPaths.map(
                    (coordinates: any) =>
                        <PolylineDecorator
                            key={`hybridpath-${coordinates}`}
                            positions={coordinates}
                            patterns={arrow_green}
                            pathOptions={{ color: MAP_FEATURE_COLORS.HYBRID, opacity: line_opacity }}
                            featureGroupRef={featureGroupRef}
                            decoratorFeatureGroupRef={decoratorFeatureGroupRef}
                            addDecoratorId={addDecoratorId}
                        />
                )}
                {responseMapFeatures && responseMapFeatures.perimeters && responseMapFeatures.perimeters.map(
                    (coordinates: any) =>
                        <PolylineDecorator
                            key={`perimeter-${coordinates}`}
                            positions={coordinates}
                            patterns={square_red}
                            pathOptions={{ color: MAP_FEATURE_COLORS.PERIMETER, opacity: line_opacity }}
                            featureGroupRef={featureGroupRef}
                            decoratorFeatureGroupRef={decoratorFeatureGroupRef}
                            addDecoratorId={addDecoratorId}
                        />
                )}
                {responseMapFeatures && responseMapFeatures.obstacles && responseMapFeatures.obstacles.map(
                    (coordinates: any) =>
                        <Polygon
                            key={`obstacle-${coordinates}`}
                            positions={coordinates}
                            pathOptions={{ color: MAP_FEATURE_COLORS.OBSTACLE, opacity: line_opacity }}
                        />
                )}
                </FeatureGroup>
                <FeatureGroup ref={decoratorFeatureGroupRef} />
            </MapContainer>
        </Box>
    );
}

export default DrawableMap;