import { createSelector } from "reselect";
import { IState } from "../@types";
import { Users } from "../@types/users";


export const usersSelector = createSelector(
   (state: IState) => state.users.users,
   (users) => {
      const filteredArray = Object.values(users).filter(user => user.role !== "super");
      let filtered: Users = {};
      
      filteredArray.forEach(user => filtered[user.userId] = user);

      return filtered;
   }
);