import { createSelector } from "reselect";
import { IState } from "../@types";
// import { AnalysisResponse } from "../@types/forms";
import { AnalysisResponseTERA } from "../../utils/AnalysisResponseTERA";
import { AnalysisResponse } from "../../utils/AnalysisResponse";


/**
 * Maps data key back to response object in an analysis
 * NOTE: May need to map analysis response field to classification survey_data in db
 */
export const analysisDataSelector = (dataKey: string) => createSelector(
    (state: IState) => state.forms.currentAnalysis,
    (state: IState) => state.forms.classifications,
    (currentAnalysis, classifications) => {
        // const k = dataKey as keyof AnalysisResponse;
        if (!currentAnalysis || !currentAnalysis.response) return; 
        const classificationId = currentAnalysis?.classification_id;
        const classification = classificationId && classifications[classificationId]
        const majorType = classification && classification.majorType;
        // console.log("MAJOR TYPE", majorType);
        let analysisResponse;
        switch(majorType) {
            case 'AHVVA':
                analysisResponse = AnalysisResponse(currentAnalysis?.response);
                break;
            case 'TERA':
                analysisResponse = AnalysisResponseTERA(currentAnalysis?.response);
                break;
            default:
                analysisResponse = AnalysisResponse(currentAnalysis?.response);
                break;
        }
        // return currentAnalysis.response[k];
        return analysisResponse.getData(dataKey);
}
);