import * as React from 'react'
import { useMemo, useEffect } from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../redux/@types';
import {
    Box,
} from "@mui/material";
import ReportOverviewCard from "./ReportOverviewCard";
import { analysesActionsAsync } from '../redux/actions/analysesActions';
import { reportsActionsAsync } from '../redux/actions/reportsActions';
import { Classification } from '../redux/@types/forms';


const POLL_INTERVAL = 5000; // 10s in ms

const Overview: React.FC<RouteComponentProps> = () => {
    const dispatch = useDispatch();
    const classifications = useSelector((state: IState) => state.forms.classifications);
    const counts = useSelector((state: IState) => state.clients.counts);
    const analyses = useSelector((state: IState) => state.analyses.analyses);
    // console.log("CLASSIFICATIONS", classifications);
    let poll: any;

    const refresh = () => {
        dispatch(analysesActionsAsync.getAnalyses.request({}));
        dispatch(reportsActionsAsync.getReports.request({}));
    }

    useEffect(() => {
        poll = setInterval(
            () => refresh(),
            POLL_INTERVAL
        );
        return () => {
            clearInterval(poll);
            poll = null;
        }
    }, []);

    const classificationsToShow = useMemo(() => {
        const toShow = Object.values(classifications).filter(classification => 
            !!counts.find(count => count.classification_id === classification.classificationId && count.remaining > 0
            || !!analyses.find(analysis => analysis.classification_id === classification.classificationId)
        ));
        return toShow;
    }, [classifications, analyses, counts]);

    return (
        <Box>
            {classificationsToShow.map(
                (classification: Classification, i: number) =>
                    <Box key={classification.classificationId} sx={{ ...(i !== classificationsToShow.length-1 && { marginBottom: '20px' })}}>
                        <ReportOverviewCard key={classification.classificationId} classificationId={classification.classificationId} />
                    </Box>
            )}
        </Box>
    );
}

export default Overview;