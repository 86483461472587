import React from "react";
import { Router } from "@reach/router";
import Layout from "../components/Layout";
import PrivateRoute from "../components/PrivateRoute";
import Login from "../components/Login";
import Dashboard from "../components/Dashboard";
import { useSelector } from "react-redux";
import { IState } from "../redux/@types";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../components/Theme";


const App = () => {
    const user = useSelector((state: IState) => state.cognito.user);
    const isLoggedIn = !!user;

    return (
        <div>
            <title>Assess Threat</title>
            <ThemeProvider theme={theme}>
                <Layout>
                    <Router>
                        <PrivateRoute isLoggedIn={isLoggedIn} path="/app/*" component={Dashboard} />
                        <Login path="/login" />
                    </Router>
                </Layout>
            </ThemeProvider>
        </div>
    )
}

export default App;