import * as React from 'react'
import { RouteComponentProps } from "@reach/router";
import {
    Box,
    Toolbar,
    Container,
} from "@mui/material";
import DrawerLeft from "../components/DrawerLeft";
import AppBarTop from "../components/AppBarTop";
import SnackbarMessage from './SnackbarMessage';


const drawerWidth = 220;

interface DashboardLayoutProps extends RouteComponentProps {
    children: React.ReactNode;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ children }) => (
    <Box sx={{ display: 'flex' }}>
        <DrawerLeft width={drawerWidth} />
        <Box sx={{ width: '100%' }}>
            <AppBarTop drawerWidth={drawerWidth} />
            <Box sx={{
                flexGrow: 1,
                height: '100vh',
            }}>
                <Toolbar />
                <Container maxWidth={false} sx={{
                    paddingTop: '40px',
                    paddingBottom: '40px',
                    overflow: 'auto'
                }}>
                    {children}
                </Container>
            </Box>
        </Box>
        <SnackbarMessage />
    </Box>
);

export default DashboardLayout;