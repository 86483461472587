import * as React from 'react'
import { Router, RouteComponentProps } from "@reach/router";
import AhvvaPage from "./AhvvaPage";


const Views: React.FC<RouteComponentProps> = () => (
    <Router>
        <AhvvaPage path="/ahvva/:analysis_id" />
    </Router>
);

export default Views;