import * as React from 'react';
import { useState, useMemo, ChangeEvent } from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { useDispatch } from 'react-redux';
import { useSelector } from "react-redux";
import { IState } from "../../redux/@types";
import {
    Box,
    Card,
    CardContent,
    Typography,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    SelectChangeEvent,
    MenuItem,
} from "@mui/material";
import { handleInputChange, handleSelectChange } from "../../utils/formHandlers";
import { usersActionsAsync } from "../../redux/actions/usersActions";
import { UserRole } from "../../redux/@types/users";
import { isValidPhoneNumber } from "../../utils/inputValidations";


interface EditUserProps extends RouteComponentProps {
    userId: string;
}

const EditUser: React.FC<EditUserProps> = ({ userId }) => {
    const dispatch = useDispatch();

    // State
    const user = useSelector((state: IState) => state.users.users[userId]);
    const [firstName, setFirstName] = useState<string>(user.firstName || "");
    const [lastName, setLastName] = useState<string>(user.lastName || "");
    const [phoneNumber, setPhoneNumber] = useState<string>(user.phoneNumber || "");
    const [jobTitle, setJobTitle] = useState<string>(user.jobTitle || "");
    const [touched, setTouched] = useState<{ [id: string]: boolean }>({});
    // Display admin if super user
    const [role, setRole] = useState<UserRole>(user.role === "super" ? "admin" : user.role || "user");

    // Functions
    const editUser = () => {
        dispatch(usersActionsAsync.editUser.request({
            userId: user.userId,
            user: {
                ...user,
                firstName,
                lastName,
                phoneNumber,
                jobTitle,
                role
            }
        }));
        navigate(`/app/users/${user.userId}`);
    }
    const handleTouched = (type: string) => () => {
        if (!touched[type]) setTouched({ ...touched, [type]: true });
    }

    const firstNameValid = firstName !== "";
    const lastNameValid = lastName !== "";
    const phoneNumberValid = !phoneNumber || isValidPhoneNumber(phoneNumber);
    const formValid = firstNameValid
        && lastNameValid
        && phoneNumberValid;

    return (
        <Box>
            <Card sx={{
                display: 'flex',
                paddingTop: '12px',
            }}>
                <CardContent sx={{ width: '600px' }}>
                    <Typography variant="h6">
                        Basic information
                    </Typography>
                </CardContent>
                <CardContent sx={{
                    width: '100%',
                    '& > div': {
                        marginBottom: '20px'
                    },
                }}>
                    <Box>
                        <TextField
                            required
                            fullWidth
                            label="First name"
                            placeholder="First name"
                            value={firstName}
                            onChange={handleInputChange(setFirstName)}
                            error={touched["firstName"] && !firstNameValid}
                            helperText={touched["firstName"] && !firstNameValid && "Invalid first name"}
                            onBlur={handleTouched("firstName")}
                        />
                    </Box>
                    <Box>
                        <TextField
                            required
                            fullWidth
                            label="Last name"
                            placeholder="Last name"
                            value={lastName}
                            onChange={handleInputChange(setLastName)}
                            error={touched["lastName"] && !lastNameValid}
                            helperText={touched["lastName"] && !lastNameValid && "Invalid last name"}
                            onBlur={handleTouched("lastName")}
                        />
                    </Box>
                    <Box>
                        <TextField
                            fullWidth
                            label="Phone number"
                            placeholder="Phone number"
                            value={phoneNumber}
                            onChange={handleInputChange(setPhoneNumber)}
                            error={touched["phoneNumber"] && !phoneNumberValid}
                            helperText={touched["phoneNumber"] && !phoneNumberValid && "Invalid phone number format"}
                            onBlur={handleTouched("phoneNumber")}
                        />
                    </Box>
                    <Box>
                        <TextField
                            fullWidth
                            label="Job title"
                            placeholder="Job title"
                            value={jobTitle}
                            onChange={handleInputChange(setJobTitle)}
                            onBlur={handleTouched("jobTitle")}
                        />
                    </Box>
                </CardContent>
            </Card>
            <Card sx={{
                display: 'flex',
                paddingTop: '12px',
            }}>
                <CardContent sx={{ width: '600px' }}>
                    <Typography variant="h6">
                        User properties
                    </Typography>
                </CardContent>
                <CardContent sx={{
                    width: '100%',
                    '& > div': {
                        marginBottom: '20px'
                    },
                }}>
                    <Box>
                        <FormControl
                            fullWidth
                            required
                        >
                            <InputLabel id="user-role-select-label">User role</InputLabel>
                            <Select
                                labelId="user-role-select-label"
                                value={role}
                                onChange={handleSelectChange(setRole)}
                                label="User role"
                            >
                                <MenuItem value="user">User</MenuItem>
                                <MenuItem value="admin">Admin</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </CardContent>
            </Card>
            <Box sx={{
                marginTop: '16px',
                '.action-btn': {
                    marginLeft: '10px',
                    fontSize: '16px',
                },
                display: 'flex',
                justifyContent: 'space-between',
            }}>
                <Box />
                <Box>
                    <Button
                        className="action-btn"
                        variant="outlined"
                        sx={{ textTransform: 'none' }}
                        onClick={() => navigate(`/app/users/${user.userId}`)}
                    >Cancel</Button>
                    <Button
                        disabled={!formValid}
                        className="action-btn"
                        variant="contained"
                        sx={{ textTransform: 'none' }}
                        onClick={editUser}
                    >Update</Button>
                </Box>
            </Box>
        </Box>
    );
}

export default EditUser;