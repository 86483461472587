import * as React from 'react';
import { useState, ChangeEvent, useEffect, useMemo } from 'react';
import { RouteComponentProps } from "@reach/router";
import { useDispatch, useSelector } from 'react-redux';
import { analysisDataSelector } from '../../redux/selectors/analysisDataSelector';
import { IState } from '../../redux/@types';
import {
    Box,
    TextField,
    FormGroup,
    FormLabel,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
} from "@mui/material";
import { stringToChecked } from "../../utils/formHandlers";
import { formsActions } from "../../redux/actions/formsActions";
import { usersSelector } from '../../redux/selectors/usersSelector';
import { superUsersSelector } from '../../redux/selectors/superUsersSelector';



interface EmailSingleInputProps extends RouteComponentProps {
    dataKey: string
    defaultValue: string
    questionText: string
    required: boolean
}

const EmailSingleInput: React.FC<EmailSingleInputProps> = ({
    defaultValue,
    dataKey,
    questionText,
    required,
}) => {
    const dispatch = useDispatch();

    const user = useSelector((state: IState) => state.users.user);
    const users = useSelector(usersSelector);
    const superUsers = useSelector(superUsersSelector);
    const initialValue = useSelector(analysisDataSelector(dataKey));
    const [value, setValue] = useState<any>(initialValue || defaultValue);

    const usersList = user && user.role === 'super' && superUsers || users;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        dispatch(formsActions.updateFormResponse(dataKey, event.target.value, "text_answer", questionText));
    };

    const combinedUsers = useMemo(() => {
        return {
            ...usersList,
            ...(user && {[user.userId]: user}), // Include current user in list
        };
    }, [user, users]);

    useEffect(() => {
        dispatch(formsActions.updateFormResponse(dataKey, value, "text_answer", questionText));
    }, []);

    return (
        <Box>
            <FormControl sx={{ maxHeight: "200px", overflow: "auto", width: "100%" }}>
                <RadioGroup
                    defaultValue={value}
                    name="email-radio"
                    onChange={handleChange}
                    value={value}
                >
                    {combinedUsers && Object.values(combinedUsers).map(user =>
                        <FormControlLabel
                            key={user.userId}
                            control={
                                <Radio />
                            }
                            value={user.email}
                            label={user.username}
                        />
                    )}
                </RadioGroup>
            </FormControl>
        </Box>
    );
}

export default EmailSingleInput;