class AhvvaContent {
    
    static barrier_colour_dict = {
        True : '#109748',
        False : '#e24100'
    };

    static barrier_speed = {
        '0, 18': 16,
        '18, 32': 32,
        '34, 51': 48,
        '51, 67': 64,
        '67, 85': 80,
        '85, 101': 96,
        '101, 117': 112
    };

    static barrier_weight = {
        '0, 1500': '1500[M1]',
        '1500, 2500': '2500[N1G]',
        '2500, 3500': '3500[N1]',
        '3500, 7500': '7500[N3]',
        '7500, 30000': '30000[N3]'
    };

    static no_barrier_text = "beyond the maximum testing parameters set by current industry standards. It is recommended that you seek further expert advice in relation to an appropriate vehicle barrier for this situation. ";
    static barrier_text = 'V/%s/%s/90';
    static no_suitable_barrier = 'no suitable barrier';

    static segment_colour_dict = {
        '0, 10' : '#007434',
        '10, 20' : '#00b050',
        '20, 30' : '#93d150',
        '30, 40' : '#c1ff00',
        '40, 50' : '#ffff00',
        '50, 60' : '#feca02',
        '60, 70' : '#ff8105',
        '70, 80' : '#fe4300',
        '80, 90' : '#cc0000',
        '90, Infinity' : '#8e0000'
    };
    static segment_colour_dict_imperial = {
        '0, 6.2' : '#007434',
        '6.2, 12.4' : '#00b050',
        '12.4, 18.6' : '#93d150',
        '18.6, 24.9' : '#c1ff00',
        '24.9, 31.1' : '#ffff00',
        '31.1, 37.3' : '#feca02',
        '37.3, 43.5' : '#ff8105',
        '43.5, 49.7' : '#fe4300',
        '49.7, 55.9' : '#cc0000',
        '55.9, Infinity' : '#8e0000'
    };

    static consequence_r = {
        '0, 25000': 'Minor',
        '25000, 95000': 'Moderate',
        '95000, Infinity': 'Severe'
    };

    static consequence_colour = {
        'Minor' : '#109748',
        'Moderate' : '#fed009',
        'Severe' : '#e24100'
    };

    static likelihood_r = {
        '0, 90': 'Likely',
        '90, 180': 'Possible',
        '180, Infinity': 'Unlikely'
    };

    static likelihood_colour = {
        'Unlikely' : '#109748',
        'Possible' : '#fed009',
        'Likely' : '#e24100'
    };

    static overall_r = {
        "Minor, Unlikely": 'Low Risk',
        "Minor, Possible": 'Low Risk',
        "Minor, Likely": 'Low Risk',

        "Moderate, Unlikely": 'Low Risk',
        "Moderate, Possible": 'Medium Risk',
        "Moderate, Likely": 'Medium Risk',

        "Severe, Unlikely": 'Low Risk',
        "Severe, Possible": 'Medium Risk',
        "Severe, Likely": 'High Risk'
    };

    static overall_colour = {
        'Low Risk' : '#109748',
        'Medium Risk' : '#fed009',
        'High Risk' : '#e24100'
    };

    static consequence_text = {
        "Minor": "A consequence rating of MINOR corresponds to a momentum at impact of up to 12,500 newton seconds. ",
        "Moderate": "A consequence rating of MODERATE corresponds to a momentum at impact of 12,500 to 95,000 newton seconds. ",
        "Severe": "A consequence rating of SEVERE corresponds to a momentum at impact of greater than 95,000 newton seconds. "
    };

    static likelihood_text = {
        "Unlikely": "A likelihood rating of UNLIKELY corresponds to an attack vector where the sum of all turn angles is greater than 180 degrees. Attack vectors with these characteristics are deemed to be difficult for the typical driver to manoeuvre through at high speeds due to the presence of sharp turns and significant or multiple changes in direction. This means there is a significant risk that a hostile vehicle would not be able to successfully undertake this attack vector at high speeds. A hostile vehicle is less likely to take this attack vector compared to less complicated attack vectors unless it is the only attack vector that is available or possible. ",
        "Possible": "A likelihood rating of POSSIBLE corresponds to an attack vector where the sum of all turn angles is between 90 and 180 degrees. Attack vectors with these characteristics present some difficulty for a hostile vehicle to manoeuvre through at high speeds. A hostile vehicle may undertake this attack vector where more direct approaches to the target are not available or possible. ",
        "Likely": "A likelihood rating of LIKELY corresponds to an attack vector where the sum of all turn angles is between 0 and 90 degrees. Attack vectors with these characteristics represent the most direct attack vectors towards the target with few or no sharp turns and changes in direction. This allows a hostile vehicle to more easily manoeuvre through this attack vector at high speeds. A hostile vehicle is most likely to take this attack vector. "
    };

    static overall_text = {
        "Low Risk": "The attack vector has been rated as LOW-RISK. This describes attack vectors that have been rated as difficult for a typical driver to manoeuvre due to the presence of sharp turns or significant changes in direction. These attack vectors possess significant risk that the driver would lose control of their vehicle whilst attempting to gain speed. ",
        "Medium Risk": "The attack vector has been rated as MODERATE-RISK. This describes attack vectors where a vehicle can generate more than 25,000 newtons of force along an attack vector that is rated as low to moderately difficult for a typical driver to manoeuvre. ",
        "High Risk": "The attack vector has been rated as HIGH-RISK. This describes attack vectors where greater than 95,000 newtons of momentum can be generated along an attack vector where there would be minimal to no difficulty for a typical driver to manoeuvre due to the absence of sharp turns or changes in direction. Such attack vectors maximise the potential damage that an incoming vehicle can cause and minimises the risk that the driver would lose control of the vehicle whilst attempting to manoeuvre through the attack vector at high speed. "
    };

    static recommendation_text = "At the point of impact for Path %s, %s would reach %s kilometres per hour and generate %s newton seconds of momentum or %s joules of kinetic energy. The recommended barrier rating required at the point of impact and based on the impact test specifications for vehicle security barrier systems in PAS 68:2013 `Impact Test Specifications For Vehicle Security Barrier Systems` is %s. This is based on a conservative estimate which assumes a 90-degree angle of impact which would maximise the transfer of force between the vehicle and the barrier. It is recommended that you seek expert advice on the most appropriate vehicle barrier system for your specific requirements.";
    static recommendation_text_imperial = "At the point of impact for Path %s, %s would reach %s miles per hour and generate %s newton seconds of momentum or %s foot-pounds of kinetic energy. The recommended barrier rating required at the point of impact and based on the impact test specifications for vehicle security barrier systems in PAS 68:2013 `Impact Test Specifications For Vehicle Security Barrier Systems` is %s. This is based on a conservative estimate which assumes a 90-degree angle of impact which would maximise the transfer of force between the vehicle and the barrier. It is recommended that you seek expert advice on the most appropriate vehicle barrier system for your specific requirements.";

    static path_colour = {
        'Low Risk': '#06f814',
        'Medium Risk': '#ffff46',
        'High Risk': '#ff0000'
    };

    /**
    Takes in a dictionary data-structure. Where keys represent upper and lower bound pairs.
    Find the key where input >= lower bound and input <= upper bound.
    Return corresponding value.
    @param input - The numerical input.
    @param rating - A dictionary data-structure where keys represent upper and lower bounds.
    @return - The value when using input as a key.
    */
    static get_rating(input, rating) {
        let return_txt = '' 
        for (let key in rating) {
            let lower = Number(key.split(',')[0])
            let higher = Number(key.split(',')[1])
            if (input >= lower && input <= higher) {
                return_txt = rating[key];
                break;
            }
        }
        return return_txt;
    }

    /**
    Given momentum of the vehicle, return the consequence rating.
    @param momentum - Momentum of the vehicle in newton seconds.
    @return - Consequence rating.
    */
    static get_consequence_rating(momentum) {
        let return_value = this.get_rating(momentum, this.consequence_r); 
        return return_value;
    }

    /**
    Return the colour corresponding with consequence rating.
    @param consequence_rating - Text of consequence_rating.
    @return - Consequence colour.
    */
    static get_consequence_colour(consequence_rating) {
        let return_value = this.consequence_colour[consequence_rating];
        return return_value;
    }

    /**
    Return text the describing the consequences of attack with given consequence rating.
    @param consequence_rating - Text of consequence_rating.
    @return - Consequence text.
    */
    static get_consequence_text(consequence_rating) {
        let return_value = this.consequence_text[consequence_rating];
        return return_value;
    }

    /**
    Given sum of angles of all turns taken by vehicle, return the likelyhood rating.
    @param sum_angle - sum of angles of all turns taken by vehicle in degrees.
    @return - Likelyhood rating.
    */
    static get_likelihood_rating(sum_angle) {
        let return_value = this.get_rating(sum_angle, this.likelihood_r); 
        return return_value;
    }

    /**
    Return the colour corresponding with likelihood rating.
    @param likelihood_rating - Text of likelihood_rating.
    @return - Likelyhood colour.
    */
    static get_likelihood_colour(likelihood_rating) {
        let return_value = this.likelihood_colour[likelihood_rating];
        return return_value;
    }

    /**
    Return text the describing the likelyhood of attack with given likelihood rating.
    @param likelihood_rating - Text of likelihood_rating.
    @return - Likelyhood text.
    */
    static get_likelihood_text(likelihood_rating) {
        let return_value = this.likelihood_text[likelihood_rating];
        return return_value;
    }

    /**
    Given consequence_rating and likelihood_rating return overall rating.
    @param consequence_rating - Text of consequence_rating.
    @param likelihood_rating - Text of likelihood_rating.
    @return - Overall rating.
    */
    static get_overall_rating(consequence_rating, likelihood_rating) {
        let rating_combined = consequence_rating + ', ' + likelihood_rating
        let return_value = this.overall_r[rating_combined];
        return return_value;
    }

    /**
    Return the colour corresponding with overall rating.
    @param overall_rating - Text of overall_rating.
    @return - Overall rating colour.
    */
    static get_overall_colour(overall_rating) {
        let return_value = this.overall_colour[overall_rating];
        return return_value;
    }

    /**
    Return the overall text given overall rating.
    @param overall_rating - Text of overall_rating.
    @return - Overall text.
    */
    static get_overall_text(overall_rating) {
        let return_value = this.overall_text[overall_rating];
        return return_value;
    }

    /**
    Return the speed rating given final speed.
    @param final_speed - Speed on impact.
    @return - Overall text.
    */
    static get_speed_rating(final_speed) {
        let return_value = this.get_rating(final_speed, this.barrier_speed);
        return return_value;
    }

    /**
    Return the text describing the weight rating given final speed.
    @param weight - Vehicle weight in kg.
    @return - Overall text.
    */
    static get_weight_rating(weight) {
        let return_value = this.get_rating(weight, this.barrier_weight);
        return return_value;
    }

    /**
    Return barrier_text given weight and final speed.
    @param weight - Vehicle weight in kg.
    @param final_speed - Speed on impact.
    @return - Overall text.
    */
    static get_barrier_text(weight, final_speed) {
        let weightRating = this.get_weight_rating(weight);
        let speedRating = this.get_speed_rating(final_speed);
        let barrier_text_new = ''
        if (weightRating && speedRating) {
            let util = require('util')
            barrier_text_new = util.format(this.barrier_text, weightRating, speedRating);
        }
        else {
            barrier_text_new = this.no_suitable_barrier;
        }
        return barrier_text_new;
    }

    /**
    Return barrier_text given weight and final speed.
    @param pair_id_int - Unique identifier for target/vehicle/path.
    @param vehicle_name - Vehicle name.
    @param velocity_km_h - Velocity on impact in km/h.
    @param momentum - Momentum on impact in newton seconds.
    @param kinetic_energy - Kinetic energy on joules.
    @param barrier_text - The text for the recommended barrier.
    @return - Recommendation text.
    */
    static get_recommendation_text(pair_id_int, vehicle_name, velocity_km_h, momentum, kinetic_energy, barrier_text) {
        let util = require('util')
        let recommendation_text_new = util.format(this.recommendation_text, pair_id_int, vehicle_name, velocity_km_h, momentum, kinetic_energy, barrier_text);
        return recommendation_text_new;
    }
    static get_recommendation_text_imperial(pair_id_int, vehicle_name, velocity_km_h, momentum, kinetic_energy, barrier_text) {
        let util = require('util')
        let recommendation_text_new = util.format(this.recommendation_text_imperial, pair_id_int, vehicle_name, velocity_km_h, momentum, kinetic_energy, barrier_text);
        return recommendation_text_new;
    }

    /**
    Return path_colour given overall rating.
    @param overall_rating - Overall rating.
    @return - The colour for paths.
    */
    static get_path_colour(overall_rating) {
        let return_value =  this.path_colour[overall_rating];
        return return_value;
    }
    
    /**
    Get colour for the path given speed.
    @param speed - Speed in km/h.
    @return - Colour value.
    */
    static get_segment_colour(speed) {
        let return_value = this.get_rating(speed, this.segment_colour_dict);
        return return_value;
    }
    static get_segment_colour_imperial(speed) {
        let return_value = this.get_rating(speed, this.segment_colour_dict_imperial);
        return return_value;
    }
}

export default AhvvaContent;
