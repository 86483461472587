import * as React from 'react';
import { useState, useMemo, ChangeEvent } from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { useDispatch } from 'react-redux';
import { useSelector } from "react-redux";
import { IState } from "../../redux/@types";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Typography,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    SelectChangeEvent,
    MenuItem,
    Modal,
} from "@mui/material";
import { handleInputChange, handleSelectChange } from "../../utils/formHandlers";
import { usersActionsAsync } from "../../redux/actions/usersActions";
import { UserRole } from "../../redux/@types/users";
import { isValidPhoneNumber } from "../../utils/inputValidations";
import usersApi from '../../redux/apis/usersApi';
import { Auth } from 'aws-amplify';
import { appActions } from '../../redux/actions/appActions';
import QRCode from "qrcode";


interface EditUserProps extends RouteComponentProps {}

const EditUser: React.FC<EditUserProps> = ({}) => {
    const dispatch = useDispatch();

    // State
    const user = useSelector((state: IState) => state.users.user);
    const apiGatewayUrl = useSelector((state: IState) => state.constants.apiGatewayUrl);
    const [firstName, setFirstName] = useState<string>(user?.firstName || "");
    const [lastName, setLastName] = useState<string>(user?.lastName || "");
    const [phoneNumber, setPhoneNumber] = useState<string>(user?.phoneNumber || "");
    const [jobTitle, setJobTitle] = useState<string>(user?.jobTitle || "");
    const [touched, setTouched] = useState<{ [id: string]: boolean }>({});
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [mfaToken, sMfaToken] = useState<string>("");
    const [qrCodeUrl, sQrCodeUrl] = useState<string>("");
    const [userCode, sUserCode] = useState<string>("");

    const changed = useMemo(() => {
        return firstName !== user?.firstName
            || lastName !== user?.lastName
            || phoneNumber !== user?.phoneNumber
            || jobTitle !== user?.jobTitle
    }, [firstName, lastName, phoneNumber, jobTitle, user]);

    // Functions
    const editUser = () => {
        if (!user) return;
        dispatch(usersActionsAsync.editUser.request({
            userId: user.userId,
            user: {
                ...user,
                firstName,
                lastName,
                phoneNumber,
                jobTitle,
            }
        }));
    }
    const handleTouched = (type: string) => () => {
        if (!touched[type]) setTouched({ ...touched, [type]: true });
    }

    const firstNameValid = firstName !== "";
    const lastNameValid = lastName !== "";
    const phoneNumberValid = !phoneNumber || isValidPhoneNumber(phoneNumber);
    const formValid = firstNameValid
        && lastNameValid
        && phoneNumberValid;
    
    const getMfaToken = async () => {
        const session = await Auth.currentSession();
        const token = session.getAccessToken().getJwtToken();
        const softwareToken = await usersApi.getMfaSoftwareToken({ apiGatewayUrl, token });
        sMfaToken(softwareToken.secret_code);
    }
    const submitMfaCode = async () => {
        const session = await Auth.currentSession();
        const token = session.getAccessToken().getJwtToken();
        try {
            const status = await usersApi.verifyMfaCode({ apiGatewayUrl, token, code: userCode });
            dispatch(appActions.setMessage({ message: "Success", severity: "success" }));
            closeModal();
            dispatch(usersActionsAsync.userInit.request({}));
        } catch (e: any) {
            dispatch(appActions.setMessage({ message: "Invalid code. Please try again.", severity: "error" }));
        }

    }
    const deleteMfa = async () => {
        const session = await Auth.currentSession();
        const token = session.getAccessToken().getJwtToken();
        await usersApi.updateMfa({ apiGatewayUrl, token, enabled: false });
        dispatch(appActions.setMessage({ message: "Deleted MFA", severity: "error" }));
        dispatch(usersActionsAsync.userInit.request({}));
    }

    const qrCode = useMemo(() => {
        QRCode.toDataURL(
            `otpauth://totp/Vardogyir:${user && user.email}?secret=${mfaToken}`,
            (err: any, url: string) => {
                sQrCodeUrl(url);
            }
        );
    }, [mfaToken]);

    const closeModal = () => {
        sMfaToken("");
        sUserCode("");
        sQrCodeUrl("");
        setModalOpen(false);
    }

    return (
        <Box>
            <Card sx={{
                display: 'flex',
                paddingTop: '12px',
            }}>
                <CardContent sx={{ width: '600px' }}>
                    <Typography variant="h6">
                        Basic information
                    </Typography>
                </CardContent>
                <CardContent sx={{
                    width: '100%',
                    '& > div': {
                        marginBottom: '20px'
                    },
                }}>
                    <Box>
                        <TextField
                            required
                            fullWidth
                            label="First name"
                            placeholder="First name"
                            value={firstName}
                            onChange={handleInputChange(setFirstName)}
                            error={touched["firstName"] && !firstNameValid}
                            helperText={touched["firstName"] && !firstNameValid && "Invalid first name"}
                            onBlur={handleTouched("firstName")}
                        />
                    </Box>
                    <Box>
                        <TextField
                            required
                            fullWidth
                            label="Last name"
                            placeholder="Last name"
                            value={lastName}
                            onChange={handleInputChange(setLastName)}
                            error={touched["lastName"] && !lastNameValid}
                            helperText={touched["lastName"] && !lastNameValid && "Invalid last name"}
                            onBlur={handleTouched("lastName")}
                        />
                    </Box>
                    <Box>
                        <TextField
                            fullWidth
                            label="Phone number"
                            placeholder="Phone number"
                            value={phoneNumber}
                            onChange={handleInputChange(setPhoneNumber)}
                            error={touched["phoneNumber"] && !phoneNumberValid}
                            helperText={touched["phoneNumber"] && !phoneNumberValid && "Invalid phone number format"}
                            onBlur={handleTouched("phoneNumber")}
                        />
                    </Box>
                    <Box>
                        <TextField
                            fullWidth
                            label="Job title"
                            placeholder="Job title"
                            value={jobTitle}
                            onChange={handleInputChange(setJobTitle)}
                            onBlur={handleTouched("jobTitle")}
                        />
                    </Box>
                    <Button
                        disabled={!formValid || !changed}
                        className="action-btn"
                        variant="contained"
                        sx={{ textTransform: 'none', float: 'right' }}
                        onClick={editUser}
                    >Save</Button>
                </CardContent>
            </Card>
            <Card sx={{
                display: 'flex',
                paddingTop: '12px',
            }}>
                <CardContent sx={{ width: '600px' }}>
                    <Typography variant="h6">
                        Multi-factor authentication
                    </Typography>
                </CardContent>
                <CardContent sx={{
                    width: '100%',
                    '& > div': {
                        marginBottom: '20px'
                    },
                }}>
                    <Typography sx={{ marginBottom: '10px' }}>
                        Configure multi-factor authentication for your account.
                    </Typography>
                    {user && user.hasMfa ?
                        <Button
                            className="action-btn"
                            variant="outlined"
                            color="error"
                            sx={{ textTransform: 'none' }}
                            onClick={deleteMfa}
                        >Delete MFA</Button> :
                        <Button
                            className="action-btn"
                            variant="outlined"
                            sx={{ textTransform: 'none' }}
                            onClick={() => setModalOpen(true)}
                        >Set up MFA</Button>
                    }
                </CardContent>
            </Card>
            {/* <Box sx={{
                marginTop: '16px',
                '.action-btn': {
                    marginLeft: '10px',
                    fontSize: '16px',
                },
                display: 'flex',
                justifyContent: 'space-between',
            }}>
                <Box />
                <Box>
                    <Button
                        disabled={!formValid || !changed}
                        className="action-btn"
                        variant="contained"
                        sx={{ textTransform: 'none' }}
                        onClick={editUser}
                    >Save</Button>
                </Box>
            </Box> */}
            <Modal
                open={modalOpen}
                onClose={closeModal}
            >
                <Card sx={{
                    position: 'absolute',
                    top: 'calc(50% - 300px)',
                    left: 'calc(50% - 250px)',
                    width: '500px',
                    height: '650px',
                    bgcolor: 'background.paper',
                    borderRadius: '3px',
                    overflow: 'auto',
                }}>
                    <CardHeader title={`Set up multi-factor authentication`} />
                    <Divider />
                    <CardContent
                        sx={{
                            height: '89%',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Box sx={{ marginBottom: '20px' }}>
                            <Typography>
                                Step 1. Get a setup key.
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={getMfaToken}
                                sx={{ textTransform: 'none' }}
                            >{`Get ${mfaToken && "new" || ""} setup key`}</Button>
                        </Box>
                        {mfaToken &&
                            <Box>
                                <Box sx={{ marginBottom: '20px' }}>
                                    <Typography>
                                        2. Scan the QR code on your authenticator app.
                                    </Typography>
                                    <Box>
                                        <img src={qrCodeUrl} />
                                    </Box>
                                    <Typography>
                                        OR
                                    </Typography>
                                    <Typography>
                                        Enter your setup key into your authenticator app.
                                    </Typography>
                                    <Typography sx={{
                                        backgroundColor: "rgba(0, 0, 0, 0.06)",
                                        fontWeight: 600,
                                        wordWrap: 'break-word'
                                    }}>
                                        {`${mfaToken}`}
                                    </Typography>
                                </Box>
                                <Box sx={{ marginBottom: '20px' }}>
                                    <Typography sx={{ marginBottom: "5px" }}>
                                        3. Enter a code from your authenticator app.
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        label="User code"
                                        placeholder="User code"
                                        value={userCode}
                                        onChange={handleInputChange(sUserCode)}
                                        sx={{ width: '150px' }}
                                    />
                                <Box sx={{ marginTop: '5px' }}>
                                    <Button
                                        variant="contained"
                                        onClick={submitMfaCode}
                                        sx={{ textTransform: 'none' }}
                                    >Verify</Button>
                                </Box>
                                </Box>
                            </Box>
                        }
                    </CardContent>
                </Card>
            </Modal>
        </Box>
    );
}

export default EditUser;