import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { RouteComponentProps, navigate } from "@reach/router";
import { useSelector, useDispatch } from 'react-redux';
import {
    Box,
    Button,
    Typography,
    CircularProgress,
} from "@mui/material";
import { IState } from '../../redux/@types';
import AhvvaMap from './AhvvaMap';
import { reportsActionsAsync } from '../../redux/actions/reportsActions';
import { reportsByAnalysisIdSelector } from '../../redux/selectors/reportsByAnalysisIdSelector';
import { REPORT_STATUS } from '../../constants';


const POLL_INTERVAL = 10000; // 10s in ms

interface AhvvaPageProps extends RouteComponentProps {}

const AhvvaPage: React.FC<AhvvaPageProps> = () => {
    const dispatch = useDispatch();
    const analysis = useSelector((state: IState) => state.forms.currentAnalysis);
    const reports = useSelector(reportsByAnalysisIdSelector(analysis?.analysis_id || ""));
    let poll: any;
    // console.log("ahvvapage", analysis);

    const [appBarOpen, sAppBarOpen] = useState<boolean>(false);

    const latestReport = useMemo(() => {
        if (reports.length > 0) return reports.reduce((a, b) => new Date(a.date_submitted) > new Date(b.date_submitted) ? a : b);
    }, [analysis, reports]);

    const toggleAppBar = () => {
        sAppBarOpen(!appBarOpen);
    }
    const generateReport = (description: string, analysisId: string) => {
        if (!description || !analysisId) return;
        dispatch(reportsActionsAsync.createReport.request({ description, analysisId }));
    }
    const downloadReport = (reportId: string) => {
        if (!reportId) return;
        dispatch(reportsActionsAsync.downloadReport.request({ reportId }));
    }

    const refresh = () => {
        dispatch(reportsActionsAsync.getReports.request({}));
    }

    useEffect(() => {
        poll = setInterval(
            () => refresh(),
            POLL_INTERVAL
        );
        return () => {
            clearInterval(poll);
            poll = null;
        }
    }, []);

    useEffect(() => {
        // If no analysis, navigate back to overview
        if (!analysis) navigate("/app");
    }, []);

    return (
        <Box sx={{
            height: "80vh",
            width: "100%"
        }}>
            <Box sx={{
                margin: '5px 0px 5px 0px',
                display: 'flex',
                '> button': {
                    margin: '5px',
                },
                justifyContent: 'right',
                height: '40px',
            }}>
                <Button
                    variant="contained"
                    onClick={() => generateReport(latestReport?.description || "", latestReport?.analysis_id || "")}
                    disabled={latestReport && Number(latestReport.status) !== REPORT_STATUS.COMPLETE}
                >
                    <Typography sx={{
                        marginLeft: '3px',
                        textTransform: 'none',
                        fontSize: '12px',
                    }}>
                        {latestReport && Number(latestReport.status) === REPORT_STATUS.IN_PROGRESS ?
                            (
                                <span>
                                    <CircularProgress
                                        color="inherit"
                                        size="1rem"
                                        sx={{
                                            left: '-5px',
                                            bottom: '-3px',
                                            position: 'relative'
                                        }}
                                    />Generating report...
                                </span>
                            ) :
                            "Regenerate report"
                        }
                    </Typography>
                </Button>
                <Button
                    variant="contained"
                    onClick={() => downloadReport(latestReport?.report_id || "")}
                    disabled={latestReport && Number(latestReport.status) !== REPORT_STATUS.COMPLETE}
                >
                    <Typography sx={{
                        marginLeft: '3px',
                        textTransform: 'none',
                        fontSize: '12px',
                    }}>
                        Download report
                    </Typography>
                </Button>
            </Box>
            {/* <AhvvaMap toggleAppBar={toggleAppBar} /> */}
            <Box sx={{
                height: "calc(100% - 40px)",
                width: "100%"
            }}>
                <AhvvaMap />
            </Box>
        </Box>
    )
};

export default AhvvaPage;