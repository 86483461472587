
export const kmToM = (v: number, toFixed?: number) => {
    return (v*0.621371).toFixed(toFixed || 1);
}
export const mToFt = (v: number, toFixed?: number) => {
    return (v*3.28084).toFixed(toFixed || 1)
}
export const kgToLb = (v: number, toFixed?: number) => {
    return (v*2.20462).toFixed(toFixed || 1);
}
export const jToFtLb = (v: number, toFixed?: number) => {
    return (v*0.737562).toFixed(toFixed || 1);
}