import * as React from 'react';
import { useState } from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { useDispatch } from 'react-redux';
import {
    Box,
    Typography,
    Card,
    CardContent,
    TextField,
    Button,
} from "@mui/material";
import { clientsActionsAsync } from "../../redux/actions/clientsActions";
import { handleInputChange } from "../../utils/formHandlers";


interface AddClientProps extends RouteComponentProps {}

const AddClient: React.FC<AddClientProps> = () => {
    const dispatch = useDispatch();
    // State
    const [clientName, setClientName] = useState<string>("")

    // Functions
    const createClient = () => {
        dispatch(clientsActionsAsync.createClient.request({ clientName }));
        navigate("/app/clients");
    }

    const formValid = clientName !== "";

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                alignItems: 'end',
                marginBottom: '24px',
            }}>
                <Typography
                    variant="h4"
                >
                    Clients
                </Typography>
                <Typography
                    variant="h6"
                    sx={{ marginLeft: '12px' }}
                >
                    / add
                </Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
            }}>
                <Box sx={{
                    display: 'flex',
                    width: '950px',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    '& > div': {
                        marginBottom: '20px'
                    },
                }}>
                    <Card sx={{
                        display: 'flex',
                        paddingTop: '12px',
                    }}>
                        <CardContent sx={{ width: '600px' }}>
                            <Typography variant="h6">
                                Basic information
                            </Typography>
                        </CardContent>
                        <CardContent sx={{
                            width: '100%',
                            '& > div': {
                                marginBottom: '20px'
                            },
                        }}>
                            <Box>
                                <TextField
                                    required
                                    fullWidth
                                    value={clientName}
                                    onChange={handleInputChange(setClientName)}
                                    label="Client name"
                                    placeholder="Client name"
                                />
                            </Box>
                        </CardContent>
                    </Card>
                    <Box sx={{
                        marginTop: '16px',
                        '.action-btn': {
                            marginLeft: '10px',
                            fontSize: '16px',
                        },
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                        <Box />
                        <Box>
                            <Button
                                className="action-btn"
                                variant="outlined"
                                sx={{ textTransform: 'none' }}
                                onClick={() => navigate("/app/clients")}
                            >Cancel</Button>
                            <Button
                                disabled={!formValid}
                                className="action-btn"
                                variant="contained"
                                sx={{ textTransform: 'none' }}
                                onClick={createClient}
                            >Submit</Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default AddClient;