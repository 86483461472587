import * as React from 'react';
import { useMemo, useState, useRef, useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import {
    Box,
} from "@mui/material";

// Leaflet
import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "react-leaflet-fullscreen/dist/styles.css";
import SingleMarkerMapEvent from './SingleMarkerMapEvent';
import MapSourceControl from "./MapSourceControl";
import MapLocateControl from './MapLocateControl';
import GeoSearch from "./GeoSearch";
import { MAX_NATIVE_ZOOM, MAX_ZOOM, MIN_ZOOM } from '../../constants';


interface MapProps extends RouteComponentProps {
    dataKey: string
    questionText?: string
}

const Map: React.FC<MapProps> = ({
    dataKey,
    questionText,
}) => {

    const [attribution, sAttribution] = useState<string>('&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors');
    const [url, sUrl] = useState<string>("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png");
    return (
        <Box
            id="map"
            sx={{
                '& .leaflet-container': {
                    height: '500px',
                    width: '100%',
                },
                '.customize-button': {
                    width: '35px',
                    height: '35px',
                    lineHeight: '30px',
                    borderTopLeftRadius: '2px',
                    borderTopRightRadius: '2px',
                    padding: '5px',
                    backgroundColor: '#fff',
                    cursor: 'pointer',
                    border: '2px solid rgba(0,0,0,.2)',
                }
            }}
        >
            <MapContainer
                center={[-33.8688, 151.2093]}
                zoom={13}
                minZoom={MIN_ZOOM}
                maxZoom={MAX_ZOOM}
                scrollWheelZoom={true}
            >
                {/* <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                /> */}
                <TileLayer
                    attribution={attribution}
                    url={url}
                    minZoom={MIN_ZOOM}
                    maxZoom={MAX_ZOOM}
                    maxNativeZoom={MAX_NATIVE_ZOOM}
                />
                <MapLocateControl />
                <GeoSearch />
                <MapSourceControl
                    sAttribution={sAttribution}
                    sUrl={sUrl}
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    iconSrc="/map.svg"
                    title="Map view"
                />
                <MapSourceControl
                    sAttribution={sAttribution}
                    sUrl={sUrl}
                    url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                    attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
                    iconSrc="/satellite.svg"
                    title="Satellite view"
                />
                <MapSourceControl
                    sAttribution={sAttribution}
                    sUrl={sUrl}
                    url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                    iconSrc="/black-map.svg"
                    title="Dark view"
                />
                <SingleMarkerMapEvent dataKey={dataKey} questionText={questionText} />
            </MapContainer>
        </Box>
    );
}

export default Map;