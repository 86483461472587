import * as React from 'react';
import { useState, useMemo, useEffect, SyntheticEvent } from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { useSelector, useDispatch } from "react-redux";
import { IState } from "../redux/@types";
import {
    Box,
    Typography,
    Snackbar,
    Alert as MuiAlert,
    AlertProps,
} from "@mui/material";


interface SnackbarMessageProps extends RouteComponentProps {}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackbarMessage: React.FC<SnackbarMessageProps> = () => {
    // State
    const [open, sOpen] = useState<boolean>(false);

    const message = useSelector((state: IState) => state.app.message);

    const handleClose = (e: SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        sOpen(false);
    }

    useEffect(() => {
        if (message.message) sOpen(true);
    }, [message]);

    return (
        <Box>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <Alert severity={message.severity}>
                    {message.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default SnackbarMessage;