import * as React from 'react';
import { useState, useRef, useEffect, useMemo } from 'react';
import { RouteComponentProps } from "@reach/router";
import { useMap } from "react-leaflet";
import L from "leaflet";
import { useDispatch, useSelector } from 'react-redux';
import { formsActions } from "../../redux/actions/formsActions";
import "leaflet-draw";


interface MapControlProps extends RouteComponentProps {
    fn: () => void,
    iconSrc: string,
    tooltip: string,
    position: "topleft" | "topright",
}

const MapControl: React.FC<MapControlProps> = ({ fn, iconSrc, tooltip, position }) => {
    const map = useMap();

    const createButtonControl = () => {
        let button: any;
        const Control = L.Control.extend({
            options: {},
            onAdd: (map: any) => {
                button = L.DomUtil.create("button", "customize-button");
                button.title = tooltip;
                button.innerHTML = `<img src="${iconSrc}" width="15" height="15" />`;
        
                button.addEventListener("click", (e: any) => {
                    e.stopPropagation();
                    fn();
                });
        
                return button;
            }
        });
        return new Control({ position });
    }

    useEffect(() => {
        const control = createButtonControl();
        control.addTo(map);
    }, []);

    return null;
}

export default MapControl;