import * as React from 'react';
import { useState } from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { useDispatch } from 'react-redux';
import { useSelector } from "react-redux";
import { IState } from "../../redux/@types";
import {
    Box,
    Card,
    CardContent,
    Typography,
    Button,
    TextField,
} from "@mui/material";
import { clientsActionsAsync } from "../../redux/actions/clientsActions";
import { handleInputChange } from "../../utils/formHandlers";


interface EditClientProps extends RouteComponentProps {
    clientId: string;
}

const EditClient: React.FC<EditClientProps> = ({ clientId }) => {
    const dispatch = useDispatch();

    // State
    const client = useSelector((state: IState) => state.clients.clients[clientId]);
    const [clientName, setClientName] = useState<string>(client.clientName || "");

    // Functions
    const editClient = () => {
        dispatch(clientsActionsAsync.editClient.request({ clientId, clientName }));
        navigate(`/app/clients/${clientId}`);
    }

    const formValid = clientName !== "";

    return (
        <Box>
            <Card sx={{
                display: 'flex',
                paddingTop: '12px',
            }}>
                <CardContent sx={{ width: '600px' }}>
                    <Typography variant="h6">
                        Basic information
                    </Typography>
                </CardContent>
                <CardContent sx={{
                    width: '100%',
                    '& > div': {
                        marginBottom: '20px'
                    },
                }}>
                    <Box>
                        <TextField
                            required
                            fullWidth
                            label="Client name"
                            placeholder="Client name"
                            value={clientName}
                            onChange={handleInputChange(setClientName)}
                        />
                    </Box>
                </CardContent>
            </Card>
            <Box sx={{
                marginTop: '16px',
                '.action-btn': {
                    marginLeft: '10px',
                    fontSize: '16px',
                },
                display: 'flex',
                justifyContent: 'space-between',
            }}>
                <Box />
                <Box>
                    <Button
                        className="action-btn"
                        variant="outlined"
                        sx={{ textTransform: 'none' }}
                        onClick={() => navigate(`/app/clients/${client.clientId}`)}
                    >Cancel</Button>
                    <Button
                        disabled={!formValid}
                        className="action-btn"
                        variant="contained"
                        sx={{ textTransform: 'none' }}
                        onClick={editClient}
                    >Update</Button>
                </Box>
            </Box>
        </Box>
    );
}

export default EditClient;