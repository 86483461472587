import * as React from 'react';
import { RouteComponentProps } from "@reach/router";
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../redux/@types';
import {
    Box,
    Toolbar,
    Container,
    Typography,
} from "@mui/material";


interface FormTitleProps extends RouteComponentProps {
    title: string
    description: string
}

const FormTitle: React.FC<FormTitleProps> = ({ title, description }) => {
    return (
        <Box>
            <Box
                sx={{
                    borderBottom: '1px solid #000',
                    marginBottom: '10px',
                }}
            >
                <Typography variant="h6">{title}</Typography>
            </Box>
            <Box>
                <Typography variant="body2">{description}</Typography>
            </Box>
        </Box>
    );
}

export default FormTitle;