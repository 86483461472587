import React from "react";
import { useMemo } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../redux/@types';
import {
    Box,
    List,
    ListItem,
    Typography,
} from "@mui/material";
import { formsActions, formsActionsAsync } from "../../redux/actions/formsActions";


interface PreviewBreadcumbsProps extends RouteComponentProps {};

const PreviewBreadcrumbs: React.FC<PreviewBreadcumbsProps> = () => {
    const dispatch = useDispatch();

    const classificationId = useSelector((state: IState) => state.forms.currentAnalysis?.classification_id);
    const classification = classificationId && useSelector((state: IState) => state.forms.classifications[classificationId]);
    const views = classification && classification?.surveyData;
    const viewIndex = useSelector((state: IState) => state.forms.control.viewIndex);

    const titles = useMemo(() => {
        if (!views) return [];
        return Object.values(views).map(view => view.title.view.data.default);
    }, [views]);

    const updateIndex = (index: number) => {
        dispatch(formsActions.updateControl({ viewIndex: index }));
    }
    
    return (
        <Box>
            <List>
                {titles.map((title, i) => (
                    <ListItem
                        key={`breadcrumb-${i}`}
                        sx={{
                            width: "250px",
                            wordWrap: "break-word",
                            marginBottom: "10px",
                            padding: "0px",
                        }}
                    >
                        <Typography
                            variant="caption"
                            sx={{
                                ...(i === viewIndex &&
                                    { fontWeight: "700" } ||
                                    { color:  "#868686" }
                                ),
                                // cursor: "pointer",
                            }}
                            // onClick={() => updateIndex(i)}
                        >
                            {title}
                        </Typography>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
}

export default PreviewBreadcrumbs;