import * as React from 'react';
import { useEffect } from 'react';
import { RouteComponentProps } from "@reach/router";
import { useMap } from "react-leaflet";
import L from "leaflet";
import { MAP_FEATURE_COLORS } from '../../constants';


interface MapBlockingControlProps extends RouteComponentProps {}

const MapBlockingControl: React.FC<MapBlockingControlProps> = () => {
    const map = useMap();

    const createButtonControl = () => {
        let button: any;
        const Control = L.Control.extend({
            options: {},
            onAdd: (map: any) => {
                button = L.DomUtil.create("button", "customize-button");
                button.title = "Draw a blocking element";
                button.innerHTML = '<img src="/polygon.svg" width="15" height="15" />';
        
                button.addEventListener("click", (e: any) => {
                    e.stopPropagation();
                    new L.Draw.Polygon(map, {
                        showArea: true,
                        shapeOptions: {
                            color: MAP_FEATURE_COLORS.OBSTACLE
                        }
                    }).enable();
                });
        
                return button;
            }
        });
        return new Control({ position: "topright" });
    }

    useEffect(() => {
        const control = createButtonControl();
        control.addTo(map);
    }, []);

    return null;
}

export default MapBlockingControl;