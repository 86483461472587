import * as React from 'react';
import { useEffect } from 'react';
import { RouteComponentProps } from "@reach/router";
import { useMap } from "react-leaflet";
import L from "leaflet";


interface MapSourceControlProps extends RouteComponentProps {
    sAttribution: any
    sUrl: any
    url: string
    attribution: string
    iconSrc: string
    title?: string
}

const MapSourceControl: React.FC<MapSourceControlProps> = ({
    sAttribution, sUrl, attribution, url, iconSrc, title,
}) => {
    const map = useMap();

    const createButtonControl = () => {
        let button: any;
        const Control = L.Control.extend({
            options: {},
            onAdd: (map: any) => {
                button = L.DomUtil.create("button", "customize-button");
                button.title = title;
                button.innerHTML = `<img src="${iconSrc}" width="15" height="15" />`;
        
                button.addEventListener("click", (e: any) => {
                    e.stopPropagation();
                    sUrl(url);
                    sAttribution(attribution);
                });
        
                return button;
            }
        });
        return new Control({ position: "topleft" });
    }

    useEffect(() => {
        const control = createButtonControl();
        control.addTo(map);
    }, []);

    return null;
}

export default MapSourceControl;