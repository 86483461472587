import * as React from 'react';
import { useState, useMemo, useEffect } from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { useSelector } from 'react-redux';
import { IState } from '../../redux/@types';
import {
    Box,
    Button,
    Typography,
    Paper,
    InputBase,
    Divider,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    TableSortLabel,
    Tooltip,
} from "@mui/material";
import {
    Search as SearchIcon,
    Edit as EditIcon,
    ArrowForward as ArrowForwardIcon,
    Add as AddIcon,
} from "@mui/icons-material";
import { Client } from '../../redux/@types/clients';
import debounce from "lodash.debounce";
import { handleInputChange } from '../../utils/formHandlers';


const rowsPerPageOptions = [10, 25, 50];

const headCells: {
    id: string,
    numeric: boolean,
    label: string,
    width?: string,
    sortable: boolean,
    align?: "right" | "left" | "center"
}[] = [
    {
        id: 'clientName',
        numeric: false,
        label: 'CLIENT NAME',
        sortable: true
    },
    {
        id: 'noUsers',
        numeric: true,
        label: 'NO. USERS',
        sortable: true
    },
    {
        id: 'createdDate',
        numeric: false,
        label: 'CREATED DATE',
        sortable: true
    },
    {
        id: 'quotaRemaining',
        numeric: true,
        label: 'REMAINING',
        sortable: true
    },
    {
        id: 'totalSubmitted',
        numeric: true,
        label: 'SUBMITTED',
        sortable: true
    },
    {
        id: 'actions',
        numeric: false,
        label: 'ACTIONS',
        align: 'right',
        sortable: false
    },
]

interface ClientsIndexProps extends RouteComponentProps {}

const ClientsIndex: React.FC<ClientsIndexProps> = () => {
    // State
    const [rowsPerPage, setRowsPerPage] = useState<number>(rowsPerPageOptions[0]);
    const [page, setPage] = useState<number>(0);
    const clients = useSelector((state: IState) => state.clients.clients);
    const [filteredClients, setFilteredClients] = useState<Client[]>(Object.values(clients));
    const [orderBy, setOrderBy] = useState<"clientName" | "createdDate" | "noUsers" | "quotaRemaining" | "totalSubmitted">("clientName");
    const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
    const counts = useSelector((state: IState) => state.clients.counts);
    const classifications = useSelector((state: IState) => state.forms.classifications);

    const getClientCounts = (clientId: string) => {
        let clientCounts: any = {};
        const countsArray = counts.filter(count => count.client_id === clientId);
        clientCounts.total = countsArray.reduce((a, c) => a + c.remaining, 0);
        clientCounts.breakdown = countsArray
            .map(counts => `${classifications[counts.classification_id]?.description}: ${counts.remaining}`)
            .join(", ");
        return clientCounts;
    }

    const rows = useMemo(() => {
        return filteredClients.map(client => {
            const counts = getClientCounts(client.clientId);
            return {
                clientId: client.clientId,
                clientName: client.clientName,
                createdDate: client.createdDate,
                noUsers: client.noUsers,
                quotaRemaining: counts.total,
                breakdown: counts.breakdown,
                totalSubmitted: client.totalSubmitted
            };
        });
    } , [filteredClients, counts]);
    const sorted = useMemo(() => {
        let sorted = rows;
        sorted.sort((a, b) => {
            if (!a.clientName) return 1;
            if (!b.clientName) return -1;
            const aClientName = a.clientName.toLowerCase();
            const bClientName = b.clientName.toLowerCase();
            if (aClientName > bClientName) return -1;
            else return 1;
        });
        if (orderBy === "createdDate") {
            sorted.sort((a, b) => {
                if (!a.createdDate) return 1;
                if (!b.createdDate) return -1;
                    return sortOrder === "desc" ? new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
                        : new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime(); 
            });
            // console.log("sorted by submitted date");
        } else if (orderBy === 'noUsers' || orderBy === 'quotaRemaining' || orderBy === 'totalSubmitted') {
            sorted.sort((a, b) => {
                if (sortOrder === "desc") {
                    return b[orderBy] - a[orderBy];
                } else {
                    return a[orderBy] - b[orderBy];
                }
            });
        } else {
            sorted.sort((a, b) => {
                if (!a[orderBy]) return 1;
                if (!b[orderBy]) return -1;
                // Case insensitive sort
                const aOrderBy = a[orderBy].toLowerCase();
                const bOrderBy = b[orderBy].toLowerCase();
                if (sortOrder === "desc") {
                    if (aOrderBy < bOrderBy) return -1;
                    if (aOrderBy > bOrderBy) return 1;
                } else {
                    if (aOrderBy > bOrderBy) return -1;
                    if (aOrderBy < bOrderBy) return 1;
                }
                return 0;
            });
        }
        // console.log("rows sorted",sorted);
        return sorted;
    }, [rows, sortOrder, orderBy]);

    useEffect(() => {
        filterClients("");
    }, [clients]);

    // Functions
    const filterClients = (s: string) => {
        const clientList = Object.values(clients);
        if (!s) return setFilteredClients(clientList);

        const lowerS = s.toLowerCase();
        const debouncedFilter = debounce(() => {
            setFilteredClients(clientList.filter(client => {
                return client.clientName.toLowerCase().includes(lowerS);
            }));
        }, 500); // 500ms debounce
        debouncedFilter();
    }
    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage);
    }
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to first page
    }

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '24px',
            }}>
                <Typography
                    variant="h4"
                >
                    Clients
                </Typography>
                <Button
                    variant="contained"
                    onClick={() => navigate("/app/clients/add") }
                >
                    <AddIcon fontSize="small"/>
                    <Typography sx={{
                        marginLeft: '3px',
                        textTransform: 'none',
                        fontSize: '18px',
                    }}>Add</Typography>
                </Button>
            </Box>
            <Paper>
                <Box>
                    <Box sx={{
                        padding: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}>
                            <Box sx={{ display: 'flex', flexGrow: 1 }}>
                                <IconButton sx={{ p: '5px' }}>
                                    <SearchIcon />
                                </IconButton>
                                <InputBase
                                    id="client-search"
                                    placeholder="Search clients"
                                    sx={{
                                        marginLeft: '24px',
                                        width: '100%'
                                    }}
                                    onChange={handleInputChange(filterClients)}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Divider />
                    <Box>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {
                                        headCells.map(headCell => (
                                            <TableCell key={headCell.id} width={headCell.width} align={headCell.align || undefined}>
                                                {
                                                    headCell.sortable ?
                                                        <TableSortLabel
                                                            direction={sortOrder}
                                                            onClick={() => {
                                                                if (orderBy !== headCell.id) {
                                                                    // @ts-ignore
                                                                    setOrderBy(headCell.id)
                                                                    setSortOrder("asc");
                                                                } else {
                                                                    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                                                                }
                                                            }}
                                                        >
                                                            <Typography variant="body2">
                                                                {headCell.label}
                                                            </Typography>
                                                        </TableSortLabel> :
                                                        <Typography variant="body2">
                                                            {headCell.label}
                                                        </Typography>
                                                }
                                            </TableCell>
                                        ))
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sorted
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => (
                                    <TableRow
                                        key={`client-${row.clientId}`}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                    <TableCell component="th" scope="row">
                                        <Typography variant="body2">
                                            {row.clientName}
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <Typography variant="body2">
                                            {row.noUsers}
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <Typography variant="body2">
                                            {row.createdDate && new Date(row.createdDate).toLocaleString() || ""}
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <Tooltip title={row.breakdown}>
                                            <Typography variant="body2">
                                                {row.quotaRemaining}
                                            </Typography>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <Typography variant="body2">
                                            {row.totalSubmitted}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Box>
                                            <IconButton
                                                onClick={() => navigate(`/app/clients/${row.clientId}#edit`)}
                                            >
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => navigate(`/app/clients/${row.clientId}`)}
                                            >
                                                <ArrowForwardIcon fontSize="small" />
                                            </IconButton>
                                        </Box>
                                    </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <Divider />
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptions}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
};

export default ClientsIndex;