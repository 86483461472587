import { createSelector } from "reselect";
import { IState } from "../@types";
import { AnalysisResponse } from "../@types/forms";
import { Coordinate } from "../@types/forms";


export const analysisDataResponseMapSelector = createSelector(
    (state: IState) => state.forms.currentAnalysis,
    (currentAnalysis) => {
        if (!currentAnalysis || !currentAnalysis.response) return; 
        const resBlocks = currentAnalysis.response.map.blocks;
        const resTargets = currentAnalysis.response.map.targets;
        let markers: any = [];
        let manualPaths: any = [];
        let hybridPaths: any = [];
        let perimeters: any = [];

        const coordinateToArray = (coordinate: Coordinate) => {
            return [coordinate.latitude, coordinate.longitude];
        }

        const obstacles = resBlocks.map(block => block.obstacle.map(coordinate => coordinateToArray(coordinate)));
        resTargets.forEach(target => {
            if (Object.keys(target.single).length > 0)
                return markers.push(coordinateToArray(target.single));
            if (target.user_specified.length > 0)
                return manualPaths.push(target.user_specified.map(coordinate => coordinateToArray(coordinate)));
            if (target.sequence.length > 0)
                return hybridPaths.push(target.sequence.map(coordinate => coordinateToArray(coordinate)));
            if (target.perimeter.length > 0)
                return perimeters.push(target.perimeter.map(coordinate => coordinateToArray(coordinate)));
        });

        return { obstacles, markers, manualPaths, hybridPaths, perimeters };
    }
);