import * as React from 'react';
import { useEffect } from 'react';
import { RouteComponentProps } from "@reach/router";
import { useMap } from "react-leaflet";
import L from "leaflet";


interface MapMarkerControlProps extends RouteComponentProps {}

const MapMarkerControl: React.FC<MapMarkerControlProps> = () => {
    const markerIcon = typeof window !== 'undefined' && L.icon({
        iconUrl: "/poi_marker1.svg",
        iconSize: [80, 80], // size of the icon
        iconAnchor: [40, 40], // point of the icon which will correspond to marker's location
        popupAnchor: [0, -40] // point from which the popup should open relative to the iconAnchor
    });
    const map = useMap();

    const createButtonControl = () => {
        let button: any;
        const Control = L.Control.extend({
            options: {},
            onAdd: (map: any) => {
                button = L.DomUtil.create("button", "customize-button");
                button.title = "Set an auto-path marker";
                button.innerHTML = '<img src="/crosshair1.svg" width="15" height="15" />';
        
                button.addEventListener("click", (e: any) => {
                    e.stopPropagation();
                    new L.Draw.Marker(map, {
                        ...(markerIcon && { icon: markerIcon })
                    }).enable();
                });
        
                return button;
            }
        });
        return new Control({ position: "topright" });
    }

    useEffect(() => {
        const control = createButtonControl();
        control.addTo(map);
    }, []);

    return null;
}

export default MapMarkerControl;