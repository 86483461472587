import * as React from 'react';
import { useState, ChangeEvent, useEffect } from 'react';
import { RouteComponentProps } from "@reach/router";
import { useDispatch, useSelector } from 'react-redux';
import { analysisDataSelector } from '../../redux/selectors/analysisDataSelector';
import { IState } from '../../redux/@types';
import {
    Box,
    RadioGroup,
    Radio,
    FormControlLabel,
} from "@mui/material";
import { handleInputChange } from "../../utils/formHandlers";
import { formsActions } from "../../redux/actions/formsActions";



interface SingleChoiceInputProps extends RouteComponentProps {
    dataKey: string
    defaultValue: any
    questionText: string
    required: boolean
}

const SingleChoiceInput: React.FC<SingleChoiceInputProps> = ({
    defaultValue,
    dataKey,
    questionText,
    required,
}) => {
    const dispatch = useDispatch();

    const selectedValue = useSelector(analysisDataSelector(dataKey));
    const [value, setValue] = useState<any>(selectedValue);

    const onChange = (v: any) => {
        setValue(v);
        dispatch(formsActions.updateFormResponse(dataKey, v, "single_answer", questionText));
    }

    useEffect(() => {
        // dispatch(formsActions.updateFormResponse(dataKey, null, "single_answer", questionText));
    }, []);

    return (
        <Box>
            <RadioGroup>
                {
                    defaultValue && defaultValue.map((v: string[]) => (
                        <FormControlLabel
                            key={`radio-${dataKey}-${v[0]}`}
                            value={v[0]}
                            control={<Radio />}
                            checked={!!value && !!v && value[0] === v[0]}
                            onChange={() => onChange(v)}
                            label={v[1]}
                            sx={{ marginBottom: '10px' }}
                        />
                    ))
                }
            </RadioGroup>
        </Box>
    );
}

export default SingleChoiceInput;