import * as React from 'react';
import { RouteComponentProps } from "@reach/router";
import {
    Box,
    Typography,
} from "@mui/material";


interface RowTextProps extends RouteComponentProps {
    text: string
    title: boolean
}

const RowText: React.FC<RowTextProps> = ({
    text,
    title,
}) => {
    return (
        <Box>
            <Typography variant={title && "h5" || "body1"}>{text}</Typography>
        </Box>
    );
}

export default RowText;