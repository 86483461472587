import * as React from 'react';
import { useEffect } from 'react';
import { RouteComponentProps } from "@reach/router";
import { useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet.fullscreen/Control.FullScreen";
import "leaflet.fullscreen/Control.Fullscreen.css";
import "./MapFullscreenControl.css";


interface MapLocateControlProps extends RouteComponentProps {}

const MapLocateControl: React.FC<MapLocateControlProps> = ({}) => {
    const map = useMap();

    const createButtonControl = () => {
        const control = L.control.fullscreen({
            position: 'topleft'
        });
        return control;
    }

    useEffect(() => {
        const control = createButtonControl();
        control.addTo(map);
    }, []);

    return null;
}

export default MapLocateControl;