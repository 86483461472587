import { createSelector } from "reselect";
import { IState } from "../@types";


/**
 * Maps data key back to response object in an analysis
 * NOTE: May need to map analysis response field to classification survey_data in db
 */
export const analysesByClassificationSelector = (classificationId: string) => createSelector(
    (state: IState) => state.analyses.analyses,
    (analyses) => {
        return analyses.filter(analysis => analysis.classification_id === classificationId);
    }
);