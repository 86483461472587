import React, { useRef, useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import { Polyline } from "react-leaflet";
import L from "leaflet";
import "leaflet-polylinedecorator";
import { useMap } from "react-leaflet";


interface PolylineDecoratorProps extends RouteComponentProps {
    positions: any,
    patterns: any,
    pathOptions: any,
    featureGroupRef: any,
    decoratorFeatureGroupRef: any,
    addDecoratorId: any
}

const PolylineDecorator: React.FC<PolylineDecoratorProps> = ({
    positions,
    patterns,
    pathOptions,
    featureGroupRef,
    decoratorFeatureGroupRef,
    addDecoratorId
}) => {
    const map = useMap();

    useEffect(() => {
        // const polyline = new L.Polyline(positions, pathOptions).addTo(map);
        const polyline = new L.Polyline(positions, pathOptions);
        const decorator = L.polylineDecorator(polyline, { patterns });
        if (featureGroupRef && featureGroupRef.current) {
            polyline.addTo(featureGroupRef.current);
            decorator.addTo(decoratorFeatureGroupRef.current);
            addDecoratorId(L.stamp(decorator), L.stamp(polyline));
        }
    }, []);

    return null;
}

export default PolylineDecorator;