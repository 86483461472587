import * as React from 'react'
import { Router, RouteComponentProps } from "@reach/router";
import ClientsIndex from "./ClientsIndex";
import AddClient from "./AddClient";
import ClientDetails from "./ClientDetails";


const Clients: React.FC<RouteComponentProps> = () => (
    <Router>
        <ClientsIndex default path="/" />
        <AddClient path="/add" />
        <ClientDetails path="/:clientid/*" />
    </Router>
);

export default Clients;