import * as React from 'react';
import { useState, ChangeEvent, useEffect } from 'react';
import { RouteComponentProps } from "@reach/router";
import { useDispatch, useSelector } from 'react-redux';
import { analysisDataSelector } from '../../redux/selectors/analysisDataSelector';
import { IState } from '../../redux/@types';
import {
    Box,
    RadioGroup,
    Radio,
    FormControlLabel,
} from "@mui/material";
import { handleInputChange } from "../../utils/formHandlers";
import { formsActions } from "../../redux/actions/formsActions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



interface CalendarInputProps extends RouteComponentProps {
    dataKey: string
    defaultValue: any
    questionText: string
    required: boolean
}

const CalendarInput: React.FC<CalendarInputProps> = ({
    defaultValue,
    dataKey,
    questionText,
    required,
}) => {
    const dispatch = useDispatch();

    const initialValue = useSelector(analysisDataSelector(dataKey));
    const [value, setValue] = useState<any>(initialValue);

    const onChange = (date: any) => {
        setValue(date.getTime());
        dispatch(formsActions.updateFormResponse(dataKey, date.getTime(), "calendar_answer", questionText));
    }

    useEffect(() => {
        // dispatch(formsActions.updateFormResponse(dataKey, value, "calendar_answer", questionText));
    }, []);

    return (
        <Box sx={{
            '.date-input': {
                padding: '8.5px 14px',
                width: '272px',
                borderRadius: '4px',
                fontSize: '1rem',
                fontWeight: '400',
                fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                border: '1px solid'
            }
        }}>
            <DatePicker
                className={'date-input'}
                selected={value}
                onChange={onChange}
                dateFormat="MMMM d, yyyy"
            />
        </Box>
    );
}

export default CalendarInput;