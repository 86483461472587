import * as React from 'react'
import { Router, RouteComponentProps } from "@reach/router";
import FormPreview from './FormPreview';


const Forms: React.FC<RouteComponentProps> = () => (
    <Router>
        <FormPreview path="/:analysis_id" />
    </Router>
);

export default Forms;