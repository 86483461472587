import * as React from 'react';
import { useState, useMemo, ChangeEvent } from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { useDispatch } from 'react-redux';
import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { IState } from "../../redux/@types";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Typography,
    Divider,
    Button,
    Modal,
    TextField,
} from "@mui/material";
import {
    Edit as EditIcon,
} from "@mui/icons-material";
import EditUser from "./EditUser";
import { usersActionsAsync } from "../../redux/actions/usersActions";
import { handleInputChange } from '../../utils/formHandlers';
import { isValidPassword } from "../../utils/inputValidations";


interface UserDetailsProps extends RouteComponentProps {}

const UserDetails: React.FC<UserDetailsProps> = () => {
    const dispatch = useDispatch();

    const pathname = typeof window !== "undefined" && window.location.pathname;
    const hash = typeof window !== "undefined" && window.location.hash;
    const routes = pathname && pathname.split('/') || [];
    const edit = hash === "#edit";
    const userId = routes[routes.length-1];

    // State
    const user = useSelector((state: IState) => state.users.users[userId]);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [password, setPassword] = useState<string>("");
    const [password2, setPassword2] = useState<string>("");
    const [pwModalOpen, setPwModalOpen] = useState<boolean>(false);
    const [touched, setTouched] = useState<{ [id: string]: boolean }>({});

    const passwordMatch = password === password2;;
    const passwordStringValid = isValidPassword(password);
    const passwordValid = passwordStringValid && passwordMatch;

    // Functions
    const deleteUser = () => {
        if (user) {
            dispatch(usersActionsAsync.deleteUser.request({ userId: user.userId }));
            setModalOpen(false);
        }
        navigate("/app/users");
    }
    const resetPassword = () => {
        if (user) {
            dispatch(usersActionsAsync.resetPassword.request({ userId: user.userId, password }));
            closePwModal();
        }
    }
    const closePwModal = () => {
        setPassword("");
        setPassword2("");
        setTouched({});
        setPwModalOpen(false);
    }
    const handleTouched = (type: string) => () => {
        if (!touched[type]) setTouched({ ...touched, [type]: true });
    }

    const items = useMemo(() => {
        return user && [
            { key: "userid", title: "User ID", value: user.userId },
            { key: "firstname", title: "First name", value: user.firstName },
            { key: "lastname", title: "Last name", value: user.lastName },
            { key: "phonenumber", title: "Phone number", value: user.phoneNumber },
            { key: "email", title: "Email address", value: user.email },
            { key: "jobtitle", title: "Job title", value: user.jobTitle },
            { key: "role", title: "Role", value: user.role },
        ] || [];
    }, [user]);

    if (!user) navigate("/app/users");

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                marginBottom: '24px',
                justifyContent: 'space-between'
            }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'end',
                }}>
                    <Typography
                        variant="h4"
                    >
                        Users
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{ marginLeft: '12px' }}
                    >
                        / {user && user.username} {edit && "/ edit"}
                    </Typography>
                </Box>
                {!edit && <Button
                    variant="contained"
                    onClick={() => navigate(`/app/users/${userId}#edit`) }
                >
                    <EditIcon fontSize="small"/>
                    <Typography sx={{
                        marginLeft: '3px',
                        textTransform: 'none',
                        fontSize: '18px',
                    }}>Edit</Typography>
                </Button>}
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
            }}>
                <Box sx={{
                    display: 'flex',
                    width: '950px',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    '& > div > div': {
                        marginBottom: '20px'
                    },
                }}>
                    {edit && <EditUser userId={userId} />}
                    {!edit && <Box>
                        <Card>
                            <CardHeader title="Basic information"/>
                            <Divider />
                            {items.map((item, i) => (
                                <Box key={item.key}>
                                    <CardContent sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                width: '250px',
                                            }}
                                        >{item.title}</Typography>
                                        <Typography variant="body2">{item.value}</Typography>
                                    </CardContent>
                                    <Divider />
                                </Box>
                            ))}
                            <Box>
                                <CardContent>
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        sx={{ textTransform: 'none' }}
                                        onClick={() => setPwModalOpen(true)}
                                    >Reset password</Button>
                                </CardContent>
                            </Box>
                        </Card>
                        <Card>
                            <CardHeader title="User management"/>
                            <Divider />
                            <CardContent>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    sx={{ textTransform: 'none' }}
                                    onClick={() => setModalOpen(true)}
                                >Delete user</Button>
                            </CardContent>
                        </Card>
                    </Box>}
                </Box>
            </Box>
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
            >
                <Card sx={{
                    position: 'absolute',
                    top: 'calc(50% - 100px)',
                    left: 'calc(50% - 200px)',
                    width: '400px',
                    bgcolor: 'background.paper',
                    borderRadius: '3px'
                }}>
                    <CardHeader title={`Delete ${user && user.username}?`} />
                    <Divider />
                    <CardContent
                        sx={{ display: 'flex', alignItems: 'center' }}
                    >
                        <Button
                            variant="contained"
                            color="error"
                            onClick={deleteUser}
                        >Delete</Button>
                    </CardContent>
                </Card>
            </Modal>
            <Modal
                open={pwModalOpen}
                onClose={() => closePwModal()}
            >
                <Card sx={{
                    position: 'absolute',
                    top: 'calc(50% - 100px)',
                    left: 'calc(50% - 200px)',
                    width: '400px',
                    bgcolor: 'background.paper',
                    borderRadius: '3px'
                }}>
                    <CardHeader title={`Reset password for ${user && user.username}`} />
                    <Divider />
                    <CardContent
                        sx={{
                            'div:not(last-child)': {
                                marginBottom: '5px',
                            }
                        }}
                    >
                        <Box>
                            <Typography>
                                Set a new temporary password
                            </Typography>
                        </Box>
                        <Box>
                            <TextField
                                required
                                fullWidth
                                label="New Password"
                                placeholder="Password"
                                value={password}
                                onChange={handleInputChange(setPassword)}
                                error={touched["password"] && !(passwordValid)}
                                helperText={touched["password"] && !passwordValid && `${!passwordStringValid && "Invalid password" || ""}${!passwordStringValid && !passwordMatch && "; " || ""}${!passwordMatch && "Password does not match." || ""}`}
                                onBlur={handleTouched("password")}
                            />
                        </Box>
                        <Box>
                            <TextField
                                required
                                fullWidth
                                label="Confirm password"
                                placeholder="Confirm password"
                                value={password2}
                                onChange={handleInputChange(setPassword2)}
                                error={touched["password2"] && !(passwordMatch)}
                                helperText={touched["password2"] && !passwordMatch && "Password does not match"}
                                onBlur={handleTouched("password2")}
                            />
                        </Box>
                        <Box>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={resetPassword}
                            >Reset</Button>
                        </Box>
                    </CardContent>
                </Card>
            </Modal>
        </Box>
    );
};

export default UserDetails;