import * as React from 'react';
import { useState, useMemo, useEffect } from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { useSelector } from 'react-redux';
import { IState } from '../../redux/@types';
import {
    Box,
    Button,
    Typography,
    Paper,
    InputBase,
    Divider,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    TableSortLabel,
    Tooltip,
} from "@mui/material";
import {
    Search as SearchIcon,
    Edit as EditIcon,
    ArrowForward as ArrowForwardIcon,
    Add as AddIcon,
} from "@mui/icons-material";
import { User } from '../../redux/@types/users';
import debounce from "lodash.debounce";
import { handleInputChange } from '../../utils/formHandlers';
import { usersSelector } from '../../redux/selectors/usersSelector';


const rowsPerPageOptions = [10, 25, 50];

const headCells: {
    id: string,
    numeric: boolean,
    label: string,
    width?: string,
    sortable: boolean,
    align?: "right" | "left" | "center",
    hide?: boolean
}[] = [
    {
        id: 'username',
        numeric: false,
        label: 'USERNAME',
        width: '10%',
        sortable: true
    },
    {
        id: 'email',
        numeric: false,
        label: 'EMAIL',
        width: '20%',
        sortable: true
    },
    {
        id: 'clientName',
        numeric: false,
        label: 'CLIENT NAME',
        width: '10%',
        sortable: true
    },
    {
        id: 'createdDate',
        numeric: false,
        label: 'CREATED DATE',
        width: '15%',
        sortable: true
    },
    {
        id: 'lastLogin',
        numeric: false,
        label: 'LAST LOGIN',
        width: '15%',
        sortable: true
    },
    {
        id: 'totalSubmitted',
        numeric: true,
        label: 'SUBMITTED',
        width: '5%',
        sortable: true
    },
    {
        id: 'lastSubmitted',
        numeric: false,
        label: 'LAST SUBMITTED',
        width: '15%',
        sortable: true
    },
    {
        id: 'actions',
        numeric: false,
        label: 'ACTIONS',
        sortable: false,
        width: '10%',
        align: 'right'
    },
]

interface UsersIndexProps extends RouteComponentProps {}

const UsersIndex: React.FC<UsersIndexProps> = () => {
    // State
    const [rowsPerPage, setRowsPerPage] = useState<number>(rowsPerPageOptions[0]);
    const [page, setPage] = useState<number>(0);
    // const users = useSelector((state: IState) => state.users.users);
    const user = useSelector((state: IState) => state.users.user);
    const users = useSelector(usersSelector);
    const clients = useSelector((state: IState) => state.clients.clients);
    const counts = useSelector((state: IState) => state.analyses.counts);
    const classifications = useSelector((state: IState) => state.forms.classifications);
    const [filteredUsers, setFilteredUsers] = useState<User[]>(Object.values(users));
    const [orderBy, setOrderBy] = useState<"username" | "createdDate" | "email" | "lastLogin" | "clientName" | "totalSubmitted" | "lastSubmitted">("username");
    const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

    const headCellsToShow = useMemo(() => {
        headCells.forEach((d) => {
            if (d.id === "clientName" && user && user.role !== 'super') d.hide = true;
        });
    }, [user, filteredUsers, headCells]);
    const getUserCounts = (userId: string) => {
        let userCounts: {
            total?: number,
            breakdown?: string,
            lastSubmitted?: Date
        } = {};
        const countsArray = counts.filter(count => count.user_id === userId);
        // console.log(counts, countsArray, userId);
        userCounts.total = countsArray.reduce((a, c) => a + c.count, 0);
        userCounts.breakdown = countsArray
            .map(counts => `${classifications[counts.classification_id]?.description}: ${counts.count}`)
            .join(", ");
        countsArray.forEach((count) => {
            if (!userCounts.lastSubmitted && count.last_submitted_date) userCounts.lastSubmitted = new Date(count.last_submitted_date);
            if (count.last_submitted_date && userCounts.lastSubmitted && new Date(count.last_submitted_date).getTime() > userCounts.lastSubmitted.getTime())
                userCounts.lastSubmitted = new Date(count.last_submitted_date);
        });
        return userCounts;
    }

    const rows = useMemo(() => {
        return filteredUsers.map(user => {
            const counts = getUserCounts(user.userId);
            return {
                userId: user.userId,
                username: user.username,
                email: user.email,
                clientId: user.clientId,
                clientName: clients && user && user.clientId && clients[user.clientId] && clients[user.clientId].clientName || "",
                createdDate: user.createdDate,
                lastLogin: user.lastLogin,
                breakdown: counts.breakdown,
                totalSubmitted: counts.total || 0,
                lastSubmitted: counts.lastSubmitted
            };
        });
    } , [filteredUsers, counts, user, clients]);

    useEffect(() => {
        filterUsers("");
    }, [users]);

    const sorted = useMemo(() => {
        let sorted = rows;
        sorted.sort((a, b) => {
            if (!a.username) return 1;
            if (!b.username) return -1;
            const aClientName = a.username.toLowerCase();
            const bClientName = b.username.toLowerCase();
            if (aClientName > bClientName) return -1;
            else return 1;
        });
        if (orderBy === "createdDate" || orderBy === "lastLogin" || orderBy === "lastSubmitted") {
            sorted.sort((a, b) => {
                if (orderBy === 'createdDate') {
                    if (!a.createdDate) return 1;
                    if (!b.createdDate) return -1;
                    return sortOrder === "desc" ? new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
                        : new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime(); 
                } else if (orderBy === 'lastLogin') {
                    if (!a.lastLogin) return 1;
                    if (!b.lastLogin) return -1;
                    return sortOrder === "desc" ? new Date(b.lastLogin).getTime() - new Date(a.lastLogin).getTime()
                        : new Date(a.lastLogin).getTime() - new Date(b.lastLogin).getTime(); 
                } else {
                    if (!a.lastSubmitted) return 1;
                    if (!b.lastSubmitted) return -1;
                    return sortOrder === "desc" ? new Date(b.lastSubmitted).getTime() - new Date(a.lastSubmitted).getTime()
                        : new Date(a.lastSubmitted).getTime() - new Date(b.lastSubmitted).getTime(); 
                }
            });
        } else if (orderBy === "totalSubmitted") {
            sorted.sort((a, b) => {
                if (sortOrder === "desc") {
                    return b[orderBy] - a[orderBy];
                } else {
                    return a[orderBy] - b[orderBy];
                }
            });
        } else {
            sorted.sort((a, b) => {
                if (!a[orderBy]) return 1;
                if (!b[orderBy]) return -1;
                // Case insensitive sort
                const aOrderBy = a[orderBy].toLowerCase();
                const bOrderBy = b[orderBy].toLowerCase();
                if (sortOrder === "desc") {
                    if (aOrderBy < bOrderBy) return -1;
                    if (aOrderBy > bOrderBy) return 1;
                } else {
                    if (aOrderBy > bOrderBy) return -1;
                    if (aOrderBy < bOrderBy) return 1;
                }
                return 0;
            });
        }
        // console.log("rows sorted",sorted);
        return sorted;
    }, [rows, sortOrder, orderBy]);

    // Functions
    const filterUsers = (s: string) => {
        const userList = Object.values(users);
        if (!s) return setFilteredUsers(userList);

        const lowerS = s.toLowerCase();
        const debouncedFilter = debounce(() => {
            setFilteredUsers(userList.filter(user => {
                return user.username.toLowerCase().includes(lowerS) ||
                    user.email.toLowerCase().includes(lowerS);
            }));
        }, 500); // 500ms debounce
        debouncedFilter();
    }
    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage);
    }
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to first page
    }

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '24px',
            }}>
                <Typography
                    variant="h4"
                >
                    Users
                </Typography>
                <Button
                    variant="contained"
                    onClick={() => navigate("/app/users/add") }
                >
                    <AddIcon fontSize="small"/>
                    <Typography sx={{
                        marginLeft: '3px',
                        textTransform: 'none',
                        fontSize: '18px',
                    }}>Add</Typography>
                </Button>
            </Box>
            <Paper>
                <Box>
                    <Box sx={{
                        padding: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}>
                            <Box sx={{ display: 'flex', flexGrow: 1 }}>
                                <IconButton sx={{ p: '5px' }}>
                                    <SearchIcon />
                                </IconButton>
                                <InputBase
                                    id="user-search"
                                    placeholder="Search users"
                                    sx={{
                                        marginLeft: '24px',
                                        width: '100%'
                                    }}
                                    onChange={handleInputChange(filterUsers)}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Divider />
                    <Box>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {
                                        headCells.map(headCell => (
                                            <TableCell
                                                key={headCell.id}
                                                width={headCell.width}
                                                align={headCell.align || undefined}
                                                sx={{ ...(headCell.hide === true ? { display: 'none' } : null) }}
                                            >
                                                {
                                                    headCell.sortable ? 
                                                        <TableSortLabel
                                                            direction={sortOrder}
                                                            onClick={() => {
                                                                if (orderBy !== headCell.id) {
                                                                    // @ts-ignore
                                                                    setOrderBy(headCell.id)
                                                                    setSortOrder("asc");
                                                                } else {
                                                                    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                                                                }
                                                            }}
                                                        >
                                                            <Typography variant="body2">
                                                                {headCell.label}
                                                            </Typography>
                                                        </TableSortLabel> :
                                                        <Typography variant="body2">
                                                            {headCell.label}
                                                        </Typography>
                                                }
                                            </TableCell>
                                        ))
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ wordBreak: 'break-all' }}>
                                {sorted
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => (
                                    <TableRow
                                        key={`user-${row.userId}`}
                                        sx={{
                                            '&:last-child td, &:last-child th': { border: 0 },
                                            // '> p': { wordBreak: 'break-all' }
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            <Typography variant="body2">
                                                {row.username}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2">
                                                {row.email}
                                            </Typography>
                                        </TableCell>
                                        {user && user.role === 'super' && <TableCell component="th" scope="row">
                                            <Typography variant="body2">
                                                {row.clientName}
                                            </Typography>
                                        </TableCell>}
                                        <TableCell>
                                            <Typography variant="body2">
                                                {row.createdDate && new Date(row.createdDate).toLocaleString() || ""}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2">
                                                {row.lastLogin && new Date(row.lastLogin).toLocaleString() || ""}
                                            </Typography>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Tooltip title={row.breakdown}>
                                                <Typography variant="body2">
                                                    {row.totalSubmitted}
                                                </Typography>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2">
                                                {row.lastSubmitted && new Date(row.lastSubmitted).toLocaleString() || ""}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Box>
                                                <IconButton
                                                    onClick={() => navigate(`/app/users/${row.userId}#edit`)}
                                                >
                                                    <EditIcon fontSize="small" />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => navigate(`/app/users/${row.userId}`)}
                                                >
                                                    <ArrowForwardIcon fontSize="small" />
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <Divider />
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptions}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
};

export default UsersIndex;