import * as React from 'react';
import { useEffect } from 'react';
import { RouteComponentProps } from "@reach/router";
import { useMap } from "react-leaflet";
import L from "leaflet";
import AhvvaContent from './AhvvaContent';
import "./MapLegendControl.css";
import { kmToM } from '../../utils/imperial';


interface MapLegendControlProps extends RouteComponentProps {
    isImperial?: boolean;
}

const MapLegendControl: React.FC<MapLegendControlProps> = ({ isImperial }) => {
    // const markerIcon = typeof window !== 'undefined' && L.icon({
    //     iconUrl: "/poi_marker1.svg",
    //     iconSize: [80, 80], // size of the icon
    //     iconAnchor: [40, 40], // point of the icon which will correspond to marker's location
    //     popupAnchor: [0, -40] // point from which the popup should open relative to the iconAnchor
    // });
    const map = useMap();

    const createButtonControl = () => {
        let button: any;
        const Control = L.Control.extend({
            options: {},
            onAdd: (map: any) => {
                // button = L.DomUtil.create("button", "customize-button");
                // button.title = "Set an auto-path marker";
                // button.innerHTML = '<img src="/crosshair1.svg" width="15" height="15" />';
        
                // button.addEventListener("click", (e: any) => {
                //     e.stopPropagation();
                //     new L.Draw.Marker(map, {
                //         ...(markerIcon && { icon: markerIcon })
                //     }).enable();
                // });
        
                // return button;

                const div = L.DomUtil.create("div", "info legend");
                const initGrades = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
                const grades = isImperial && initGrades.map(v => +kmToM(v)) || initGrades;
                const labels = [];
                let from;
                let to;
          
                for (let i = 0; i < grades.length; i++) {
                    from = grades[i];
                    to = grades[i + 1];
            
                    labels.push(
                        '<i style="background:' +
                        (isImperial && AhvvaContent.get_segment_colour_imperial(from + 1) || AhvvaContent.get_segment_colour(from + 1)) +
                            '; width: 24px; height: 12px; border-radius: 8px"></i> ' +
                            from +
                            (to ? "&ndash;" + to : "+") +
                            `${isImperial && "m/h" || "km/h"}`
                    );
                }

                div.innerHTML = labels.join("<br>");
                return div;
            }
        });
        return new Control({ position: "bottomright" });
    }

    useEffect(() => {
        const control = createButtonControl();
        control.addTo(map);
    }, []);

    return null;
}

export default MapLegendControl;