import * as React from 'react';
import { useEffect } from 'react';
import { RouteComponentProps } from "@reach/router";
import { useMap } from "react-leaflet";
import L from "leaflet";
import "./GeoSearch.css";


const markerIcon = typeof window !== 'undefined' && L.icon({
    iconUrl: "/speed_marker.png",
    className: "marker-icon-located",
    iconSize: [20, 20], // size of the icon
    iconAnchor: [10, 10], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -10] // point from which the popup should open relative to the iconAnchor
});

interface MapLocateControlProps extends RouteComponentProps {}

const MapLocateControl: React.FC<MapLocateControlProps> = ({}) => {
    const map = useMap();

    let marker: any;

    const createButtonControl = () => {
        let button: any;
        const Control = L.Control.extend({
            options: {},
            onAdd: (map: any) => {
                button = L.DomUtil.create("button", "customize-button");
                button.title = "Locate me";
                button.innerHTML = '<img src="/location.svg" width="15" height="15" />';
        
                button.addEventListener("click", (e: any) => {
                    e.stopPropagation();
                    if (map != null) {
                        map.locate({ setView: true,  })
                            .on('locationfound', (e: any) => {
                                if (marker) {
                                    marker.setLatLng([e.latitude, e.longitude]);
                                } else {
                                    marker = L.marker([e.latitude, e.longitude], { icon: markerIcon || undefined });
                                    map.addLayer(marker);
                                }
                            });
                    }
                });
        
                return button;
            }
        });
        return new Control({ position: "topleft" });
    }

    useEffect(() => {
        const control = createButtonControl();
        control.addTo(map);
    }, []);

    return null;
}

export default MapLocateControl;