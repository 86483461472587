import * as React from 'react';
import { useState, ChangeEvent, useEffect } from 'react';
import { RouteComponentProps } from "@reach/router";
import { useDispatch, useSelector } from 'react-redux';
import { analysisDataSelector } from '../../redux/selectors/analysisDataSelector';
import { IState } from '../../redux/@types';
import {
    Box,
    TextField,
} from "@mui/material";
import { handleInputChange } from "../../utils/formHandlers";
import { formsActions } from "../../redux/actions/formsActions";


interface NumberInputProps extends RouteComponentProps {
    dataKey: string
    defaultValue: string
    questionText: string
    required: boolean
}

const NumberInput: React.FC<NumberInputProps> = ({
    dataKey,
    defaultValue,
    questionText,
    required,
}) => {
    const dispatch = useDispatch();

    const initialValue = useSelector(analysisDataSelector(dataKey));
    // console.log("NUMBERINPUT", dataKey, initialValue, initialValue === 0, defaultValue);
    // console.log("VALUE RES", initialValue || initialValue === 0 && "0" || defaultValue);
    const [value, setValue] = useState<any>(initialValue || initialValue === 0 && "0" || defaultValue);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (
            e.target.value !== "" && !(/^\d+$/.test(e.target.value))
        ) return;
        setValue(e.target.value);
        dispatch(formsActions.updateFormResponse(dataKey, e.target.value, "number_answer", questionText));
    }

    useEffect(() => {
        dispatch(formsActions.updateFormResponse(dataKey, value, "number_answer", questionText));
    }, []);

    return (
        <Box>
            <TextField
                size="small"
                sx={{
                    width: '50%',
                }}
                value={value}
                onChange={onChange}
            />
        </Box>
    );
}

export default NumberInput;