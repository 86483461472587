import * as React from 'react';
import { useEffect } from 'react';
import { RouteComponentProps } from "@reach/router";
import { useMap } from "react-leaflet";
import L from "leaflet";
import { GoogleProvider, GeoSearchControl, SearchControl } from "leaflet-geosearch";
import 'leaflet-geosearch/dist/geosearch.css';
import "./GeoSearch.css";


// const markerIcon = typeof window !== 'undefined' && L.icon({
//     iconUrl: "/markerIcon.svg",
//     iconSize: [40, 40], // size of the icon
//     iconAnchor: [20, 20], // point of the icon which will correspond to marker's location
//     popupAnchor: [0, -20] // point from which the popup should open relative to the iconAnchor
// });
const markerIcon = typeof window !== 'undefined' && L.icon({
    iconUrl: "/speed_marker.png",
    className: "marker-icon-located",
    iconSize: [20, 20], // size of the icon
    iconAnchor: [10, 10], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -10] // point from which the popup should open relative to the iconAnchor
});

interface GeoSearchProps extends RouteComponentProps {}

const GeoSearch: React.FC<GeoSearchProps> = () => {
    const map = useMap();

    const createLeafletElement = () => {
        const provider = new GoogleProvider({
            apiKey:'AIzaSyD8pfiWAL3zlDERGAaysjtx2e_Gh44yYjQ',
            params: { 
                region: 'au'
            },
        });
        //@ts-ignore
        // const searchControl = new GeoSearchControl({
        const searchControl = new SearchControl({
            provider: provider,
            style: "button",
            showMarker: true,
            marker: {
                icon: markerIcon,
                draggable: false,
            },
            showPopup: false,
            autoComplete: true,
            autoCompleteDelay: 250,
            autoClose: true,
            retainZoomLevel: false,
            animateZoom: true,
            keepResult: true,
            searchLabel: "Search for a location"
        });
        return searchControl;
    }

    useEffect(() => {
        const control = createLeafletElement();
        // control.addTo(map);
        map.addControl(control);
        const func = (e: any) => {
            var evt = e ? e : window.event;
            if (evt.stopPropagation) {
                //W3C
                evt.stopPropagation();
            } else {
                //IE
                evt.cancelBubble = true;
            }
        };
        var element: HTMLElement = document.getElementsByClassName("leaflet-control-geosearch")[0] as HTMLElement;
        element.onclick = func;
        element.ondblclick = func;
    }, []);

    return null;
}

export default GeoSearch;