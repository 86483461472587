import * as React from 'react';
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { RouteComponentProps } from "@reach/router";
import { useMap } from "react-leaflet";
import { Coordinate } from '../../redux/@types/forms';
import L from "leaflet";
import "leaflet-draw";
import { useDispatch } from 'react-redux';
import { formsActions } from '../../redux/actions/formsActions';
import { MAP_FEATURE_COLORS } from '../../constants';
import { EditControl } from "react-leaflet-draw";


interface DrawEventsProps extends RouteComponentProps {
    featureGroupRef: any,
    decoratorFeatureGroupRef: any,
    decoratorIdsRef: any
}

const DrawEvents: React.FC<DrawEventsProps> = ({
    featureGroupRef,
    decoratorFeatureGroupRef,
    decoratorIdsRef
}) => {
    const dispatch = useDispatch();
    const map = useMap();

    const getLatLng = (layer: any, type: any) => {
        if (type === "polyline") {
            return {
                latitude: layer._latlngs[0].lat,
                longitude: layer._latlngs[0].lng
            };
        } else if (type === "marker") {
            return {
                latitude: layer._latlng.lat,
                longitude: layer._latlng.lng
            };
        } else if (type === "polygon") {
            return {
                latitude: layer._latlngs[0][0].lat,
                longitude: layer._latlngs[0][0].lng
            };
        }
    }
    
    const getShapeType = (layer: any) => {
        if (layer instanceof L.Circle) {
            return "circle";
        }
        if (layer instanceof L.Marker) {
            return "marker";
        }
        if (layer instanceof L.Polyline && !(layer instanceof L.Polygon)) {
            return "polyline";
        }
        if (layer instanceof L.Polygon && !(layer instanceof L.Rectangle)) {
            return "polygon";
        }
        if (layer instanceof L.Rectangle) {
            return "rectangle";
        }
    };

    useEffect(() => {
        map.on(L.Draw.Event.CREATED, (e: any) => {
            const type = e.layerType;

            // console.log("HELLO CREATE EVENT", e);
            // console.log("TYPE", type);

            switch(type) {
                case "rectangle":
                    // dispatch(formsActions.addFormResponseMapTargetSequence(
                    //     e.layer._latlngs.map((latlng: any) => ({ latitude: latlng.lat, longitude: latlng.lng }))
                    // ));
                    break;
                case "marker":
                    dispatch(formsActions.addFormResponseMapTargetSingle({
                        latitude: e.layer._latlng.lat,
                        longitude: e.layer._latlng.lng
                    }));
                    break;
                case "polygon":
                    dispatch(formsActions.addFormResponseMapBlockObstacle(
                        e.layer._latlngs[0].map((latlng: any) => ({ latitude: latlng.lat, longitude: latlng.lng }))
                    ));
                    break;
                case "polyline":
                    if (e.layer.options.color === MAP_FEATURE_COLORS.MANUAL) {
                        dispatch(formsActions.addFormResponseMapTargetUserSpecified(
                            e.layer._latlngs.map((latlng: any) => ({ latitude: latlng.lat, longitude: latlng.lng }))
                        ));
                    } else if (e.layer.options.color === MAP_FEATURE_COLORS.PERIMETER) {
                        dispatch(formsActions.addFormResponseMapTargetPerimeter(
                            e.layer._latlngs.map((latlng: any) => ({ latitude: latlng.lat, longitude: latlng.lng })),
                            25 // PLACEHOLDER VALUE FOR NOW
                        ));
                    } else if (e.layer.options.color === MAP_FEATURE_COLORS.HYBRID) {
                        dispatch(formsActions.addFormResponseMapTargetSequence(
                            e.layer._latlngs.map((latlng: any) => ({ latitude: latlng.lat, longitude: latlng.lng }))
                        ));
                    }
                    break;
                default:
                    break;
            }

            // console.log("DISPATCHED");
        });
        map.on(L.Draw.Event.EDITED, (e) => {

        });
        map.on(L.Draw.Event.DELETED, (e) => {
            // console.log("DELETED FEATURE", e);
            
            // Type definition is incorrect
            // @ts-ignore
            Object.keys(e.layers._layers).forEach(layerId => {
                // @ts-ignore
                const layer = e.layers._layers[layerId];
                // Remove decorator feature & remove from ref if exists
                if (decoratorIdsRef.current[layerId]) {
                    decoratorFeatureGroupRef.current.removeLayer(decoratorIdsRef.current[layerId]);
                    delete decoratorIdsRef.current[layerId];
                }
                // Remove from redux state
                const type = getShapeType(layer);
                // const coord = getLatLng(layer.toGeoJSON().geometry.coordinates, type);
                const coord = getLatLng(layer, type);
                if (coord) dispatch(formsActions.removeFormResponseMapFeature(coord)); 
            })
        });
        map.on(L.Draw.Event.DELETESTOP, (e) => {

        });
    }, []);

    return null;
}

export default DrawEvents;