import { MIN_PASSWORD_LENGTH } from "../constants";


export const isValidPhoneNumber = (s: string) => {
    if (s.charAt(0) !== "+") return false;
    return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(s);
}

export const isValidEmail = (s: string) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(s)) return true;
    return false;
}

export const isValidPassword = (s: string) => {
    return /[A-Z]/.test(s)
        && /[a-z]/.test(s)
        && /[0-9]/.test(s)
        && /[^A-Za-z0-9]/.test(s)
        && s.length > MIN_PASSWORD_LENGTH;
}