import * as React from 'react'
import { RouteComponentProps } from "@reach/router";
import Helmet from 'react-helmet';


interface LayoutProps extends RouteComponentProps {
    children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => (
    <div>
        <Helmet>
            <meta
                name="viewport"
                content="width=device-width, initial-scale=0.75, shrink-to-fit=no"
            />
            <meta http-equiv="content-type" content="text/html;  charset=utf-8" />
        </Helmet>
        <div>
            {children}
        </div>
    </div>
);

export default Layout;