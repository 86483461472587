import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { RouteComponentProps, navigate } from "@reach/router";
import {
    Box,
} from "@mui/material";


interface RiskButtonsProps extends RouteComponentProps {
    text1: string
    text2: string
    text3: string
    text4?: string
    rating: string
}

const RiskButtons: React.FC<RiskButtonsProps> = ({
    text1,
    text2,
    text3,
    text4,
    rating
}) => {
    return (
        <Box sx={{
            marginTop: "15px",
            marginBottom: "10px",
            'button': {
                border: "none",
                borderRadius: "4px",
                fontSize: "14px",
                padding: "4px",
                fontWeight: 700,
                textTransform: "uppercase",
                color: "#FFFFFF",
                backgroundColor: "#DADADA"
            },
            '.mR7': { marginRight: "7px" },
            '.activeOne': { backgroundColor: "#00B050" },
            '.activeTwo': { backgroundColor: "#FEB65A" },
            '.activeThree': { backgroundColor: "#C81F41" },
        }}>
            <button className={`button mR7 ${text1 === rating && "activeOne"}`}>
                {text1}
            </button>
            <button className={`button mR7 ${text2 === rating && "activeTwo"}`}>
                {text2}
            </button>
            <button className={`button mR7 ${text3 === rating && "activeThree"}`}>
                {text3}
            </button>
        </Box>
    )
};

export default RiskButtons;