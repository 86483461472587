import * as React from 'react'
import { Router, RouteComponentProps } from "@reach/router";
import UsersIndex from "./UsersIndex";
import AddUser from "./AddUser";
import UserDetails from './UserDetails';


const Users: React.FC<RouteComponentProps> = () => (
    <Router>
        <UsersIndex default path="/" />
        <AddUser path="/add" />
        <UserDetails path="/:userid/*" />
    </Router>
);

export default Users;