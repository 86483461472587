import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { RouteComponentProps } from "@reach/router";
import { useMap } from "react-leaflet";


interface RecenterProps extends RouteComponentProps {
    center: [number, number]
}

const Recenter: React.FC<RecenterProps> = ({ center }) => {
    const map = useMap();

    useEffect(() => {
        const zoom = map.getZoom();
        const targetPoint = map.project(center, zoom).subtract([500 / 2, 0]); // 500 Overlay width
        const targetLatLng = map.unproject(targetPoint, zoom);;
        map.setView(targetLatLng, zoom);
    }, [center]);

    return null;
};

export default Recenter;