import * as React from 'react';
import { useMemo, useState, useRef, useCallback, useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import { useSelector } from 'react-redux';
import { formsActions } from "../../redux/actions/formsActions";
import { useDispatch } from 'react-redux';
import { formsActionsAsync } from '../../redux/actions/formsActions';
import { IState } from '../../redux/@types';
import { VehiclePath, Vehicle } from '../../redux/@types/forms';
import {
    Box,
    Drawer,
    Toolbar,
    Button,
    Modal,
    Card,
    CardHeader,
    Divider,
    CardContent,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    SelectChangeEvent,
} from "@mui/material";
// Leaflet
import {
    MapContainer,
    TileLayer,
    Marker,
    Polyline,
    Polygon,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css"
import { analysisDataResponseMapSelector } from '../../redux/selectors/analysisDataResponseMapSelector';
import MapSourceControl from '../ReportInput/MapSourceControl';
import MapControl from "../ReportInput/MapControl";
import PolylineDecorator from '../ReportInput/PolylineDecorator';
import 'leaflet-polylinedecorator'; // for L.Symbol
// Old js module without typescript definitions
// @ts-ignore
import { FullscreenControl } from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/dist/styles.css"
import PolylineSpeed from './PolylineSpeed';
import AhvvaAnalysisView from './AhvvaAnalysisView';
import Recenter from './Recenter';
import { MAP_FEATURE_COLORS, MAX_ZOOM, MAX_NATIVE_ZOOM, MIN_ZOOM } from '../../constants';
import { handleSelectChange } from '../../utils/formHandlers';
import MapLegendControl from './MapLegendControl';
import { checkImperial } from '../../utils/checkImperial';
import Heatmap from "./Heatmap";
import MapFullscreenControl from "../ReportInput/MapFullscreenControl"


export const markerIcon = typeof window !== 'undefined' && L.icon({
    iconUrl: "/poi_marker1.svg",
    iconSize: [80, 80], // size of the icon
    iconAnchor: [40, 40], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -40] // point from which the popup should open relative to the iconAnchor
});
export const markerIconDisabled = typeof window !== 'undefined' && L.icon({
    iconUrl: "/poi_marker1.svg",
    className: "marker-icon-disabled",
    iconSize: [80, 80], // size of the icon
    iconAnchor: [40, 40], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -40] // point from which the popup should open relative to the iconAnchor
});
const line_opacity = 0.5;

interface AhvvaMapProps extends RouteComponentProps {}

const AhvvaMap: React.FC<AhvvaMapProps> = () => {
    const dispatch = useDispatch();
    const currentAnalysis = useSelector((state: IState) => state.forms.currentAnalysis);
    const classificationId = useSelector((state: IState) => state.forms.currentAnalysis?.classification_id);
    const classification = classificationId && useSelector((state: IState) => state.forms.classifications[classificationId]);
    const isImperial = useMemo(() => {
        // console.log(classification);
        if (!classification) return false;
        return checkImperial(classification);
    }, [classification]);
    // console.log("CURRENT ANALYSIS", currentAnalysis);

    const [attribution, sAttribution] = useState<string>('&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors');
    const [url, sUrl] = useState<string>("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png");
    const [pathIndex, sPathIndex] = useState<number>(0);
    const [center, sCenter] = useState<[number, number]>([-33.8688, 151.2093]); // Coordinates for Sydney, Australia
    const [appBarOpen, sAppBarOpen] = useState<boolean>(false);
    const [heatmapOn, sHeatmapOn] = useState<boolean>(false);
    const [modalOpen, sModalOpen] = useState<boolean>(false);
    const appBarOpenRef = useRef(false);
    const heatmapOnRef = useRef(false);
    const [targetIndex, sTargetIndex] = useState<number>(0);

    const toggleAppBar = useCallback(() => {
        appBarOpenRef.current = !appBarOpenRef.current;
        sAppBarOpen(appBarOpenRef.current);
    }, [appBarOpenRef.current])
    const toggleHeatmap = useCallback(() => {
        // console.log("toggling hm", heatmapOnRef.current, !heatmapOnRef.current);
        heatmapOnRef.current = !heatmapOnRef.current;
        sHeatmapOn(heatmapOnRef.current);
    }, [heatmapOnRef.current])

    const result = useMemo(() => {
        return currentAnalysis?.result;
    }, [currentAnalysis])
    const features = useMemo(() => {
        if (!currentAnalysis?.result) return [];
        return currentAnalysis.result.FeatureCollection.features;
    }, [currentAnalysis]);
    const data = useMemo(() => {
        if (!currentAnalysis?.result) return [];
        // console.log("ALL DATA", currentAnalysis.result.data)
        return currentAnalysis.result.data;
    }, [currentAnalysis]);
    const blocks = useMemo(() => {
        if (!currentAnalysis?.result) return [];
        let blocks: [number, number][][] = [];
        currentAnalysis.result.data.forEach(datum => {
            blocks = blocks.concat(datum.blocks);
        });
        return blocks;
    }, [currentAnalysis]);
    const vehicles = useMemo(() => {
        if (!data || data.length < 1) return [];
        let vehicles: Vehicle[] = [];
        data.forEach(d => {
            d.vehicle_list.forEach(v => { vehicles[v.vehicle_num] = v });
        });
        // console.log("ALL VEHICLES", vehicles);
        return vehicles;
    }, [data]);
    const paths = useMemo(() => {
        if (!data || data.length < 1) return [];
        let paths: VehiclePath[] = [];
        data.forEach(d => {
            d.vehicle_list.forEach(v => {
                v.paths_list.forEach(p => { paths.push(p) });
            });
        });
        // console.log("ALL PATHS", paths);
        return paths;
    }, [data]);
    const greyPaths = useMemo(() => {
        if (!paths[pathIndex]) return [];
        const greyPaths = paths.filter(path => path.target_num !== paths[pathIndex].target_num);
        return greyPaths;
    }, [paths, pathIndex]);
    const bluePaths = useMemo(() => {
        if (!paths[pathIndex]) return [];
        const bluePaths = paths.filter((path, i) => path.target_num === paths[pathIndex].target_num && i !== pathIndex);
        return bluePaths;
    }, [paths, pathIndex]);
    const heatmapCoords = useMemo(() => {
        if (!features) return [];
        // console.log("data", data)
        let coords: any[] = [];
        // features.forEach(feature => {
        //     coords.push([
        //         feature.geometry.coordinates[1],
        //         feature.geometry.coordinates[0],
        //         feature.properties.percentile
        //     ]);
        // })

        const numPaths = paths.length;
        const numVehicles = result?.total_vehicles || 1;
        data.forEach(d => {
            d.vehicle_list.forEach(vehicle => {
                vehicle.paths_list.forEach(path => {
                    path.coordlist.forEach((coord, i) => {
                        // coords.push([coord[0], coord[1], (path.kinetic_energylist[i] || 0)/(10000*numPaths)]);
                        // coords.push([coord[0], coord[1], (path.kinetic_energylist[i] || 0)/(100000*numVehicles)]);
                        coords.push([coord[0], coord[1], (path.kinetic_energylist[i] || 0)]);
                    });
                })
            })
        })
        // console.log("coords", coords);
        const vals = coords.map(coord => coord[2])
        const max = Math.max(...vals)
        // console.log("MAX",max);
        // console.log("num paths, vehicles", numPaths, numVehicles);
        // const scale = max && (max/10) || 1;
        // const scale = max && (max/10)*numPaths || 1;
        const scale = max && (max/10)*numVehicles || 1;
        // console.log("scale",scale);
        const scaled = coords.map(coord => [coord[0], coord[1], coord[2]/scale])

        // console.log("scaled", scaled);
        // return coords;
        return scaled;
    }, [data, paths, result]);


    const pathSelect = useMemo(() => {
        return (
            <FormControl size="small">
                <InputLabel id="path-select-list">Path</InputLabel>
                <Select
                    id="path-select-list"
                    value={pathIndex.toString()}
                    label="Path"
                    onChange={handleSelectChange(sPathIndex)}
                >
                    {paths.map((path, i) => 
                        <MenuItem key={`menu-path-${i}`} value={i}>
                            {`Target ${path.target_num+1} ${vehicles[path.vehicle_num] && vehicles[path.vehicle_num].name} Path ${path.pair_id+1}`}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        );
    }, [pathIndex, paths, vehicles]);
    // const targetSelect = useMemo(() => {
    //     return (
    //         <FormControl size="small">
    //             <InputLabel id="target-select-list">Target</InputLabel>
    //             <Select
    //                 id="target-select-list"
    //                 value={targetIndex.toString()}
    //                 label="Target"
    //                 onChange={handleSelectChange(sTargetIndex)}
    //             >
    //                 {data.map((d, i) => 
    //                     <MenuItem value={i}>
    //                         {`Target ${d.target_num+1}`}
    //                     </MenuItem>
    //                 )}
    //             </Select>
    //         </FormControl>
    //     );
    // }, [targetIndex, data]);

    // const selectTarget = (e: SelectChangeEvent) => {

    // }
    const nextPath = (pos: boolean) => () => {
        if (!data || data.length < 1) return;
        if (!paths || paths.length <= 1) return; // Do nothing if only 1 path left
        let newPathIndex = 0;
        // if (pathIndex === paths.length-1) sPathIndex(pos ? 0 : pathIndex-1);
        // else if (pathIndex === 0) sPathIndex(pos ? pathIndex+1 : paths.length-1);
        // else sPathIndex(pos ? pathIndex+1 : pathIndex-1);
        if (pathIndex === paths.length-1) newPathIndex = pos ? 0 : pathIndex-1;
        else if (pathIndex === 0) newPathIndex = pos ? pathIndex+1 : paths.length-1;
        else newPathIndex = pos ? pathIndex+1 : pathIndex-1;
        sPathIndex(newPathIndex)
        sTargetIndex(paths[newPathIndex].target_num);
    }

    const deletePath = () => {
        dispatch(formsActionsAsync.deleteResultPath.request({ pairId: paths[pathIndex].pair_id }));
        paths.splice(pathIndex, 1);
        nextPath(false)();
        sModalOpen(false);
    }

    useEffect(() => {
        if (!paths[pathIndex]) return;
        // Center map on last point of path
        sCenter(paths[pathIndex].coordlist[paths[pathIndex].coordlist.length-1]);
    }, [paths, pathIndex]);

    return (
        <Box
            id="map"
            sx={{
                height: '100%',
                '& .leaflet-container': {
                    height: '100%',
                    width: '100%',
                },
                '.customize-button': {
                    width: '35px',
                    height: '35px',
                    lineHeight: '30px',
                    borderTopLeftRadius: '2px',
                    borderTopRightRadius: '2px',
                    padding: '5px',
                    backgroundColor: '#fff',
                    cursor: 'pointer',
                    border: '2px solid rgba(0,0,0,.2)',
                },
                '.marker-icon-disabled': {
                    filter: 'grayscale(100%)'
                },
            }}
        >
            <Box
                id="analysis-drawer"
                sx={{
                    ...(appBarOpen && {
                        height: '70%',
                        width: "30%",
                        minWidth: 400,
                        position: "absolute",
                        top: 180,
                        left: 180,
                        maxHeight: "80vh",
                        zIndex: 1099,
                        backgroundColor: 'white',
                    } || { display: 'none' }),
                }}
            >
                <Toolbar
                    sx={{
                        width: '100%',
                        height: '90px',
                        marginBottom: '30px'
                    }}
                >
                    <Box sx={{ height: '50px', width: '100%' }}>
                        <Box sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            'button': {
                                fontSize: '13px',
                                padding: '5px'
                            },
                            marginBottom: '15px'
                        }}>
                            <Button
                                onClick={toggleAppBar}
                                variant="text"
                            >
                                Close
                            </Button>
                            <Button
                                onClick={() => sModalOpen(true)}
                                variant="outlined"
                                color="error"
                            >
                                Delete path
                            </Button>
                            <Button
                                onClick={nextPath(false)}
                                variant="outlined"
                            >
                                Previous
                            </Button>
                            <Button
                                onClick={nextPath(true)}
                                variant="contained"
                            >
                                Next
                            </Button>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            {/* <Box sx={{ marginRight: '5px' }}>
                                {targetSelect}
                            </Box> */}
                            <Box>
                                {pathSelect}
                            </Box>
                        </Box>
                    </Box>
                </Toolbar>
                <Box sx={{ height: 'calc(100% - 55px)' }}>
                    {paths && paths[pathIndex] && vehicles && 
                    <AhvvaAnalysisView
                        currPath={paths[pathIndex]}
                        vehicle={vehicles[paths[pathIndex].vehicle_num]}
                        isImperial={isImperial}
                    />}
                </Box>
            </Box>
            <MapContainer
                center={center}
                zoom={13}
                minZoom={MIN_ZOOM}
                maxZoom={MAX_ZOOM}
                scrollWheelZoom={true}
            >
                    {/* <HeatmapLayer
                        radius= {35}
                        fitBoundsOnLoad
                        fitBoundsOnUpdate
                        points={heatmapCoords}
                        longitudeExtractor={(m: any) => m[1]}
                        latitudeExtractor={(m: any) => m[0]}
                        intensityExtractor={(m: any) => parseFloat(m[2])}
                    /> */}
                <TileLayer
                    attribution={attribution}
                    url={url}
                    minZoom={MIN_ZOOM}
                    maxZoom={MAX_ZOOM}
                    maxNativeZoom={MAX_NATIVE_ZOOM}
                />
                <Recenter center={center}/>
                <MapFullscreenControl />
                <MapSourceControl
                    sAttribution={sAttribution}
                    sUrl={sUrl}
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    iconSrc="/map.svg"
                    title="Map view"

                />
                <MapSourceControl
                    sAttribution={sAttribution}
                    sUrl={sUrl}
                    url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                    attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
                    iconSrc="/satellite.svg"
                    title="Satellite view"
                />
                <MapSourceControl
                    sAttribution={sAttribution}
                    sUrl={sUrl}
                    url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                    iconSrc="/black-map.svg"
                    title="Dark view"
                />
                <MapControl
                    fn={toggleAppBar}
                    iconSrc="/data.png"
                    tooltip="View Analysis"
                    position="topleft"
                />
                <MapControl
                    fn={toggleHeatmap}
                    iconSrc="/heat-map.svg"
                    tooltip="Heatmap"
                    position="topleft"
                />
                <MapLegendControl isImperial={isImperial} />
                {data.map(
                    (datum, i) => paths && paths[pathIndex] && i === paths[pathIndex].target_num ? <Marker
                        key={`marker-${datum.target}`}
                        position={datum.target}
                        icon={markerIcon || undefined}
                    /> : <Marker
                        key={`marker-${datum.target}`}
                        position={datum.target}
                        icon={markerIconDisabled || undefined}
                    />
                )}
                {greyPaths && greyPaths.map(
                    (path, i) => path && <PolylineSpeed
                        key={`path-grey-${i}`}
                        path={path}
                        isImperial={isImperial}
                        disabled={true}
                        colour={'grey'}
                        opacity={0.5}
                    />
                )}
                {bluePaths && bluePaths.map(
                    (path, i) => path && <PolylineSpeed
                        key={`path-blue-${i}`}
                        path={path}
                        isImperial={isImperial}
                        disabled={true}
                        colour={'blue'}
                        opacity={0.5}
                    />
                )}
                {
                    paths && paths[pathIndex] && <PolylineSpeed
                        key={`path-focused`}
                        path={paths[pathIndex]}
                        isImperial={isImperial}
                        disabled={false}
                        colour={'speed'}
                    />
                }
                {blocks.map((block, i) => <Polygon
                        key={`obstacle-${i}`}
                        positions={block}
                        pathOptions={{ color: MAP_FEATURE_COLORS.OBSTACLE, opacity: line_opacity }}
                    />
                )}
                {heatmapCoords && heatmapCoords.length > 0 &&
                    <Heatmap heatmapOn={heatmapOn} coords={heatmapCoords} />
                }
            </MapContainer>
            <Modal
                open={modalOpen}
                onClose={() => sModalOpen(false)}
            >
                <Card sx={{
                    position: 'absolute',
                    top: 'calc(50% - 100px)',
                    left: 'calc(50% - 200px)',
                    width: '400px',
                    bgcolor: 'background.paper',
                    borderRadius: '3px'
                }}>
                    <CardHeader title={
                        paths.length > 1 ?
                        `Delete ${
                            paths && paths[pathIndex]
                            && vehicles[paths[pathIndex].vehicle_num]
                            && vehicles[paths[pathIndex].vehicle_num].name
                        } Path ${paths && paths[pathIndex] && paths[pathIndex].pair_id+1}?` :
                        `Can not delete ${
                            paths && paths[pathIndex]
                            && vehicles[paths[pathIndex].vehicle_num]
                            && vehicles[paths[pathIndex].vehicle_num].name
                        } Path ${paths && paths[pathIndex] && paths[pathIndex].pair_id+1}`
                    } />
                    <Divider />
                    <CardContent sx={{}}>
                        {paths.length > 1 && <Typography component="div" variant="body1">
                            Confirm you want to delete this path from the results. This action cannot be reversed, and all data for this path will be 
                            <Box display="inline" sx={{ fontWeight: 600 }}> permanently lost </Box> 
                            once it is deleted.
                        </Typography>}
                        {paths.length <= 1 && <Typography component="div" variant="body1">
                            The last path in an AHVVA report can not be deleted.
                        </Typography>}
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                float: 'right',
                                'button': {
                                    marginLeft: '10px'
                                },
                                marginBottom: '15px'
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="error"
                                onClick={() => sModalOpen(false)}
                            >Cancel</Button>
                            {paths.length > 1 && <Button
                                variant="contained"
                                color="error"
                                onClick={deletePath}
                            >Delete</Button>}
                        </Box>
                    </CardContent>
                </Card>
            </Modal>
        </Box>
    );
}

export default AhvvaMap;