import * as React from 'react';
import { useMemo } from 'react';
import { RouteComponentProps } from "@reach/router";
import {
    Box,
    Typography,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import { VehiclePath, Vehicle } from '../../redux/@types/forms';
import AhvvaContent from './AhvvaContent';
import RiskButtons from './RiskButtons';
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { kmToM, mToFt, kgToLb, jToFtLb } from '../../utils/imperial';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
);

interface AhvvaAnalysisViewProps extends RouteComponentProps {
    currPath: VehiclePath,
    vehicle: Vehicle,
    isImperial: boolean,
}

const AhvvaAnalysisView: React.FC<AhvvaAnalysisViewProps> = ({
    currPath,
    vehicle,
    isImperial,
}) => {
    const title = useMemo(() => {
        return `${vehicle.name} Path ${currPath.pair_id+1} Analysis`;
    }, [currPath]);
    const consequenceRating = currPath && AhvvaContent.get_consequence_rating(currPath.momentum) || "";
    const consequenceText = currPath && AhvvaContent.get_consequence_text(consequenceRating) || "";
    const likelihoodRating = currPath && AhvvaContent.get_likelihood_rating(currPath.sum_angle) || "";
    const likelihoodText = currPath && AhvvaContent.get_likelihood_text(likelihoodRating) || "";
    const overallRating = currPath && AhvvaContent.get_overall_rating(consequenceRating, likelihoodRating) || "";
    const overallText = currPath && AhvvaContent.get_overall_text(overallRating) || "";
    const barrierText = currPath && AhvvaContent.get_barrier_text(vehicle.weight, currPath.velocity) || "";
    const recommendationText = currPath && isImperial && AhvvaContent.get_recommendation_text_imperial(currPath.pair_id, vehicle.name,
        kmToM(currPath.velocity), currPath.momentum, jToFtLb(currPath.kinetic_energy), barrierText)
        || currPath && AhvvaContent.get_recommendation_text(currPath.pair_id, vehicle.name,
            currPath.velocity, currPath.momentum, currPath.kinetic_energy, barrierText) 
        || "";
    
    const generateScatter = (
        xList: number[],
        yList: number[]
        ): { x: number; y: number }[] => {
        const result = [];
        for (let i = 0; i < xList.length; i++) {
            result.push({
                x: xList[i],
                y: yList[i]
            });
        }
        return result;
    };
    const dataLine1 = {
        labels: ["Scatter Dataset"],
        datasets: [
            {
                label: "Distance / Speed",
                yAxisID: "y",
                fill: false,
                lineTension: 0.1,
                showLine: true,
                backgroundColor: "#199ec2",
                borderColor: "#199ec2",
                // borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0.0,
                // borderJoinStyle: "miter",
                pointBorderColor: "#199ec2",
                pointBackgroundColor: "#fff",
                pointBorderWidth: 1,
                borderWidth: 3,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "#199ec2",
                pointHoverBorderColor: "#199ec2",
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                pointStyle: "circle",
                data: generateScatter(
                    currPath && isImperial && currPath.distlist.map((d: number) => +mToFt(d)) || currPath && currPath.distlist || [],
                    currPath && isImperial && currPath.speedlist.map((d: number) => +kmToM(d)) || currPath && currPath.speedlist || []
                )
            }
        ]
    };
    const dataLine2 = {
        labels: ["Scatter Dataset"],
        datasets: [
            {
                label: "Kinetic Energy",
                yAxisID: "y",
                fill: false,
                lineTension: 0.1,
                backgroundColor: "#ec4100",
                borderColor: "#ec4100",
                // borderCapStyle: "butt",
                showLine: true,
                borderDash: [],
                borderDashOffset: 0.0,
                // borderJoinStyle: "miter",
                pointBorderColor: "#ec4100",
                pointBackgroundColor: "#fff",
                pointBorderWidth: 1,
                borderWidth: 3,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "#ec4100",
                pointHoverBorderColor: "#ec4100",
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                pointStyle: "circle",
                data: generateScatter(
                    currPath && isImperial && currPath.distlist.map((d: number) => +mToFt(d)) || currPath && currPath.distlist || [],
                    currPath && isImperial && currPath.kinetic_energylist.map((v: number) => +jToFtLb(v)) || currPath && currPath.kinetic_energylist || []
                )
            },
        ]
    };
    // let dataLine3;
    // if (currPath.elevationlist !== null || currPath.elevationlist !== undefined) {
    // const dataLine3 = {
    //     labels: ["Scatter Dataset"],
    //     datasets: [
    //         {
    //             label: "Elevation (m)",
    //             yAxisID: "y",
    //             fill: false,
    //             showLine: true,
    //             lineTension: 0.1,
    //             backgroundColor: "#32cd32",
    //             borderColor: "#32cd32",
    //             // borderCapStyle: "butt",
    //             borderDash: [],
    //             borderDashOffset: 0.0,
    //             // borderJoinStyle: "miter",
    //             pointBorderColor: "#32cd32",
    //             pointBackgroundColor: "#fff",
    //             pointBorderWidth: 1,
    //             borderWidth: 3,
    //             pointHoverRadius: 5,
    //             pointHoverBackgroundColor: "#32cd32",
    //             pointHoverBorderColor: "#32cd32",
    //             pointHoverBorderWidth: 2,
    //             pointRadius: 1,
    //             pointHitRadius: 10,
    //             pointStyle: "circle",
    //             data: generateScatter(
    //                 currPath && isImperial && currPath.distlist.map((d: number) => +mToFt(d)) || currPath && currPath.distlist || [],
    //                 currPath && isImperial && currPath.elevationlist.map((d: number) => +mToFt(d)) || currPath && currPath.elevationlist || []
    //             )
    //         },
    //     ]
    // };
    // }

    return (
        <Box sx={{
            padding: "20px",
            height: 'calc(100% - 64px)',
            overflow: 'auto',
        }}>
            {vehicle && currPath && <Box>
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
                {title}
            </Typography>
            <Box sx={{ height: '20px' }}/>
            <Typography variant="body1" display="block">{`Velocity at impact: ${isImperial && kmToM(currPath.velocity) || currPath.velocity} ${isImperial && 'Miles' || 'Kilometers'} per Hour`}</Typography>
            <Typography variant="body1" display="block">{`Momentum at impact: ${currPath.momentum} Newton seconds`}</Typography>
            <Typography variant="body1" display="block">{`Kinetic energy at impact: ${isImperial && jToFtLb(currPath.kinetic_energy) || currPath.kinetic_energy*0.001} ${isImperial && 'Foot-pounds' || 'Kilojoules'}`}</Typography>
            <Typography variant="body1" display="block">{`Time from start to impact: ${currPath.time_total ? currPath.time_total + " seconds" : "No data available"}`}</Typography>
            <Typography variant="body1" display="block">{`Total path distance: ${currPath.distlist ? isImperial ? mToFt(currPath.distlist[currPath.distlist.length - 1]) + " feet" : currPath.distlist[currPath.distlist.length - 1] + " meters" : "No data available"}`}</Typography>
            <Typography variant="body1" display="block">{`Sum of all turns: ${currPath.sum_angle ? currPath.sum_angle + " degrees" : "No data available"}`}</Typography>
            <Box sx={{ height: '20px' }}/>
            <Typography variant="subtitle1" display="block" sx={{ fontWeight: 600 }}>{`Consequence`}</Typography>
            <RiskButtons text1={"Minor"} text2={"Moderate"} text3={"Severe"} rating={consequenceRating} />
            <Typography variant="body1" display="block">{consequenceText}</Typography>
            <Box sx={{ height: '20px' }}/>
            <Typography variant="subtitle1" display="block" sx={{ fontWeight: 600 }}>{`Likelihood`}</Typography>
            <RiskButtons text1={"Unlikely"} text2={"Possible"} text3={"Likely"} rating={likelihoodRating} />
            <Typography variant="body1" display="block">{likelihoodText}</Typography>
            <Box sx={{ height: '20px' }}/>
            <Typography variant="subtitle1" display="block" sx={{ fontWeight: 600 }}>{`Overall risk`}</Typography>
            <RiskButtons text1={"Low Risk"} text2={"Medium Risk"} text3={"High Risk"} rating={overallRating} />
            <Typography variant="body1" display="block">{overallText}</Typography>
            <Box sx={{ height: '20px' }}/>
            <Typography variant="subtitle1" display="block" sx={{ fontWeight: 600 }}>{`Comment`}</Typography>
            <Typography variant="body1" display="block">{recommendationText}</Typography>
            <Box sx={{ height: '20px' }}/>
            <Line
                data={dataLine1}
                options={{
                    responsive: true,
                    showLine: true,
                    scales: {
                        x: {
                            min: 0,
                            type: "linear",
                            position: "bottom",
                            title: {
                                display: true,
                                text: isImperial && "Distance (ft)" || "Distance (m)"
                            },
                        },
                        y: {
                            min: 0,
                            type: "linear",
                            position: "left",
                            title: {
                                display: true,
                                text: isImperial && "Velocity (m/h)" || "Velocity (km/h)"
                            },
                            ticks: {
                                stepSize: currPath.speed_step * 2
                            }
                        }
                    }
                }}
            />
            <Line
                data={dataLine2}
                options={{
                    responsive: true,
                    showLine: true,
                    scales: {
                        x: {
                            min: 0,
                            type: "linear",
                            position: "bottom",
                            title: {
                                display: true,
                                text: isImperial && "Distance (ft)" || "Distance (m)"
                            },
                        },
                        y: {
                            min: 0,
                            type: "linear",
                            position: "left",
                            title: {
                                display: true,
                                text: isImperial && "Kinetic Energy (ftlb)" || "Kinetic Energy (J)"
                            },
                            ticks: {
                                stepSize: currPath.kinetic_energy_step * 2
                            }
                        }
                    }
                }}
            />
            {/* <Line
                data={dataLine3}
                options={{
                    responsive: true,
                    showLine: true,
                    scales: {
                        x: {
                            min: 0,
                            type: "linear",
                            position: "bottom",
                            title: {
                                display: true,
                                text: isImperial && "Distance (ft)" || "Distance (m)"
                            },
                        },
                        y: {
                            min: 0,
                            type: "linear",
                            position: "left",
                            title: {
                                display: true,
                                text: isImperial && "Elevation (ft)" || "Elevation (m)"
                            },
                        }
                    }
                }}
            /> */}
            <br />
            <br />
            <div style={{ width: "100%" }}>
            {" "}
            {/* Table for analysis view */}
            <Table
                size={"small"}
                padding={"none"}
                sx={{ width: "100%" }}
            >
                <TableHead>
                <TableRow>
                    <TableCell>Class</TableCell>
                    <TableCell align="right">{isImperial && "Weight (lb)" || "Weight (kg)"}</TableCell>
                    <TableCell align="right">{isImperial && "Maximum speed (m/h)" || "Maximum speed (km/h)"}</TableCell>
                    <TableCell align="right">{isImperial && "Acceleation (ft/s2)" || "Acceleration (m/s2)"}</TableCell>
                    <TableCell align="right">Friction coefficient</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                <TableRow key={vehicle.name}>
                    <TableCell component="th" scope="row">{vehicle.name}</TableCell>
                    <TableCell align="right">{isImperial && kgToLb(vehicle.weight) || vehicle.weight}</TableCell>
                    <TableCell align="right">{isImperial && kmToM(vehicle.top_speed) || vehicle.top_speed}</TableCell>
                    <TableCell align="right">{isImperial && mToFt(vehicle.acceleration) || vehicle.acceleration}</TableCell>
                    <TableCell align="right">{vehicle.coeff_fric}</TableCell>
                </TableRow>
                </TableBody>
            </Table>
            </div>
            </Box>}
        </Box>
    )
};

export default AhvvaAnalysisView;