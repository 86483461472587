import * as React from 'react';
import { useState, useMemo, ChangeEvent } from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { useDispatch } from 'react-redux';
import { useSelector } from "react-redux";
import { IState } from "../../redux/@types";
import {
    Box,
    Typography,
} from "@mui/material";
import EditSelf from "./EditSelf";
import { usersActionsAsync } from "../../redux/actions/usersActions";


interface AccountDetailsProps extends RouteComponentProps {}

const AccountDetails: React.FC<AccountDetailsProps> = () => {
    // State
    const user = useSelector((state: IState) => state.users.user);

    if (!user) navigate("/app/users");

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                marginBottom: '24px',
                justifyContent: 'space-between'
            }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'end',
                }}>
                    <Typography
                        variant="h4"
                    >
                        {user?.username}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
            }}>
                <Box sx={{
                    display: 'flex',
                    width: '950px',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    '& > div > div': {
                        marginBottom: '20px'
                    },
                }}>
                    <EditSelf />
                </Box>
            </Box>
        </Box>
    );
};

export default AccountDetails;