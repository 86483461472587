import * as React from 'react';
import { useEffect } from 'react';
import { RouteComponentProps, navigate } from "@reach/router";
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Button,
    Typography,
    Divider,
} from "@mui/material";
import PreviewBreadcrumbs from "./PreviewBreadcrumbs";
import FormContent from "./FormContent";
import { IState } from '../../redux/@types';
import { formsActionsAsync } from '../../redux/actions/formsActions';


interface FormPreviewProps extends RouteComponentProps {}

const FormPreview: React.FC<FormPreviewProps> = () => {
    const dispatch = useDispatch();
    const analysis = useSelector((state: IState) => state.forms.currentAnalysis);

    const saveForm = () => {
        dispatch(formsActionsAsync.updateAnalysis.request({}));
        navigate("/app");
    }

    useEffect(() => {
        // If no analysis, navigate back to overview
        if (!analysis) navigate("/app");
    }, []);

    return (
        <Box sx={{ display: 'flex' }}>
            <Box>
                <PreviewBreadcrumbs />
                <Divider />
                <Button
                    sx={{ marginTop: '20px' }}
                    variant="contained"
                    onClick={saveForm}
                >
                    <Typography
                        variant="body2"
                        sx={{ marginRight: '10px' }}
                    >
                        Save
                    </Typography>
                </Button>
            </Box>
            <FormContent />
        </Box>
    )
};

export default FormPreview;