import { ChangeEvent } from "react";
import { SelectChangeEvent } from "@mui/material";


export const handleInputChange = (fn: any) => (e: ChangeEvent<HTMLInputElement>) => {
    fn(e.target.value);
}

export const handleSelectChange = (fn: any) => (e: SelectChangeEvent) => {
    fn(e.target.value);
}

export const stringToChecked = (value: string) => {
    const emails = value.split(", ");
    let newChecked: { [email: string]: boolean } = {};
    emails.forEach(email => newChecked[email] = true);
    return newChecked;
}