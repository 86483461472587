import React, { useRef, useEffect, useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import {
    FeatureGroup,
    Polyline,
    CircleMarker,
    Marker,
    Popup,
} from "react-leaflet";
import L from "leaflet";
import { useMap } from "react-leaflet";
import AhvvaContent from "./AhvvaContent.js";
import { FcOk } from 'react-icons/fc';
import { FcCancel } from 'react-icons/fc';
import { BARRIER_MAX_KINETIC_ENERGY } from "../../constants";
import { mToFt, kmToM, kgToLb } from "../../utils/imperial";


const icon_start = typeof window !== 'undefined' && L.icon({
    iconUrl: "/start_marker.svg",
    iconSize: [15, 15], // size of the icon
    iconAnchor: [7.5, 7.5], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -7.5] // point from which the popup should open relative to the iconAnchor
});
const icon_speed = typeof window !== 'undefined' && L.icon({
    iconUrl: "/speed_marker.png",
    iconSize: [7.5, 7.5], // size of the icon
    iconAnchor: [3.75, 3.75], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -3.75] // point from which the popup should open relative to the iconAnchor
});

interface PolylineSpeedProps extends RouteComponentProps {
    path: any
    isImperial?: boolean
    colour?: 'speed' | 'blue' | 'grey'
    disabled?: boolean
    weight?: number
    opacity?: number
}

const PolylineSpeed: React.FC<PolylineSpeedProps> = ({ path, isImperial, colour, disabled, weight, opacity }) => {
    const map = useMap();

    const segments = useMemo(() => {
        let segments: any = [];
        for (let i = 0; i < path.speedlist.length; i++) {
            let segment = {
                positions: i !== 0 ?
                    [path.coordlist[i-1], path.coordlist[i]]:
                    [path.coordlist[i], path.coordlist[i]],
                speed: isImperial && kmToM(path.speedlist[i]) || path.speedlist[i],
                momentum: path.momentumlist[i],
                timeToImpact: path.time_impact[i],
                distanceFromStart: isImperial && mToFt(path.distlist[i]) || path.distlist[i],
                radius: path.radiuslist[i],
                kineticEnergy: path.kinetic_energylist[i],
            };
            segments.push(segment);
        }
        return segments;
    }, [path]);

    return (
        <FeatureGroup>
            {/* <div className="segment-wrapper"> */}
            {
                segments.map((segment: any, i: number) => <Polyline
                    key={`path-segment-${i}`}
                    className={`${
                        colour === 'blue' && 'path-vehicle'
                        || colour === 'grey' && 'path-other'
                        || 'path-focused'
                    }`}
                    positions={segment.positions}
                    pathOptions={{
                        color:
                            colour === 'blue' && '#72aedb'
                            || colour === 'grey' && '#a3a3a3'
                            || (isImperial && AhvvaContent.get_segment_colour_imperial(segment.speed) || AhvvaContent.get_segment_colour(segment.speed) || "red"),
                        weight: weight || 10,
                        opacity: opacity || 1.0
                    }}
                />)
            }
            {/* </div> */}
            {
                !disabled && segments.map((segment: any, i: number) => <Marker
                    key={`path-segment-${i}`}
                    position={segment.positions[1]}
                    icon={icon_speed || undefined}
                    eventHandlers={{
                        mouseover: (e) => e.target.openPopup(),
                    }}
                >
                    <Popup>
                        <ul
                            style={{
                                listStyle: "none",
                                paddingLeft: "0"
                            }}
                        >
                            <li>{`${segment.speed} ${isImperial && "m/h" || "km/h"}`}</li>
                            <li>{`${segment.momentum} N`}</li>
                            <li>{`${segment.timeToImpact} sec to impact`}</li>
                            <li>{`${segment.distanceFromStart}${isImperial && "ft" || "m"} from start`}</li>
                            <li>{`${segment.radius} m turn radius`}</li>
                            <li>
                                {segment.kineticEnergy <= BARRIER_MAX_KINETIC_ENERGY.RATING_1 ?
                                <FcOk /> : <FcCancel />}
                                {` ${isImperial && kgToLb(1800, 0)+"lb" || "1800kg"} concrete barrier`}
                            </li>
                            <li>
                                {segment.kineticEnergy <= BARRIER_MAX_KINETIC_ENERGY.RATING_2 ?
                                <FcOk /> : <FcCancel />}
                                {` ${isImperial && kgToLb(7500, 0)+"lb" || "7500kg"} parked truck`}
                            </li>
                            <li>
                                {segment.kineticEnergy <= BARRIER_MAX_KINETIC_ENERGY.RATING_3 ?
                                <FcOk /> : <FcCancel />}
                                {` Portable vehicle barrier`}
                            </li>
                            <li>
                                {segment.kineticEnergy <= BARRIER_MAX_KINETIC_ENERGY.RATING_4 ?
                                <FcOk /> : <FcCancel />}
                                {` Bollard (rated to ${isImperial && kgToLb(7500, 0)+"lb" || "7500kg"} at ${isImperial && kmToM(48, 0)+"m/h" || "48km/h"})`}
                            </li>
                            <li>
                                {segment.kineticEnergy <= BARRIER_MAX_KINETIC_ENERGY.RATING_5 ?
                                <FcOk /> : <FcCancel />}
                                {` Bollard (rated to ${isImperial && kgToLb(30000, 0)+"lb" || "30000kg"} at ${isImperial && kmToM(48, 0)+"m/h" || "48km/h"})`}
                            </li>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`https://maps.google.com/maps?q=&layer=c&cbll=${segment.positions[1][0]},${segment.positions[1][1]}`}
                            >
                                Street view
                            </a>
                        </ul>
                    </Popup>
                </Marker>)
                // segments.map((segment: any, i: number) => <CircleMarker
                //     key={`path-segment-${i}`}
                //     center={segment.positions[1]}
                //     radius={5}
                //     pathOptions={{
                //         color: 'black',
                //         fillColor: 'white',
                //         weight: 0.1,
                //         fillOpacity: 1,
                //     }}
                //     // onMouseOver={() => {}}
                // />)
            }
        </FeatureGroup>
    );
}

export default PolylineSpeed;