import * as React from "react";
import { useState } from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import {
    Box,
    AppBar,
    Toolbar,
    Typography,
    Card,
    CardContent,
    Popover,
    Divider,
} from "@mui/material";
import {
    AccountCircle as AccountCircleIcon,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import { IState } from "../redux/@types";
import { Auth } from "aws-amplify";


interface AppBarTopProps extends RouteComponentProps {
    drawerWidth?: number;
}

const AppBarTop: React.FC<AppBarTopProps> = ({
    drawerWidth = 240,
}) => {
    const user = useSelector((state: IState) => state.cognito.user);
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = async () => {
        try {
            await Auth.signOut();
        } catch (err) {
            console.log("logout err");
            console.log(err);
        }
    }
    const editAccount = () => {
        navigate("/app/account");
        handleClose();
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Box sx={{
            display: 'flex',
        }}>
            <AppBar position="fixed">
                <Toolbar
                    sx={{
                        width: `calc(100% - ${drawerWidth}px)`,
                        marginLeft: `${drawerWidth}px`,
                        display: 'flex',
                        justifyContent: 'end',
                    }}
                >
                    <Box
                        sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer'
                        }}
                        onClick={(e) => handleClick(e)}
                    >
                        <AccountCircleIcon fontSize='large' />
                        <Typography sx={{ marginLeft: '12px' }}>{user && user.username}</Typography>
                    </Box>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    >
                        <Box sx={{ padding: '10px', width: '130px' }}>
                            <Typography sx={{ cursor: 'pointer' }}
                                onClick={editAccount}
                            >Account</Typography>
                        </Box>
                        <Divider />
                        <Box sx={{ padding: '10px', width: '130px' }}>
                            <Typography sx={{ cursor: 'pointer' }}
                                onClick={handleLogout}
                            >Logout</Typography>
                        </Box>
                    </Popover>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default AppBarTop;