import { createSelector } from "reselect";
import { IState } from "../@types";


export const countsSelector = (clientId?: string) => createSelector(
    (state: IState) => state.clients.counts,
    (state: IState) => state.users.user,
    (counts, user) => {
        if (!user) return null;
        if (clientId) {
           return counts.filter(count => count.client_id === clientId);
        } else if (user.role === "super") {
           return counts.filter(count => count.client_id === "");
        } else {
           return counts.filter(count => count.client_id === user.clientId);
        }
    }
);