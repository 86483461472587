import * as React from 'react';
import { useState, useRef, useEffect, useMemo } from 'react';
import { RouteComponentProps } from "@reach/router";
import { useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet-draw";
import { useDispatch } from 'react-redux';


interface MapDeleteControlProps extends RouteComponentProps {
    featureGroupRef: any
}

const MapDeleteControl: React.FC<MapDeleteControlProps> = ({ featureGroupRef }) => {
    const dispatch = useDispatch();
    const map = useMap();

    const createButtonControl = () => {
        const drawControl = new L.Control.Draw({
            edit: {
                featureGroup: featureGroupRef && featureGroupRef.current,
                edit: false,
                remove: true,
            },
            position: "topright",
            draw: {
                circle: false,
                rectangle: false,
                polygon: false,
                marker: false,
                polyline: false,
                circlemarker: false
            }
        });
        return drawControl;
    }

    useEffect(() => {
        const control = createButtonControl();
        control.addTo(map);
    }, []);

    return null;
}

export default MapDeleteControl;