import * as React from 'react';
import { useState, ChangeEvent, useEffect } from 'react';
import { RouteComponentProps } from "@reach/router";
import { useDispatch, useSelector } from 'react-redux';
import { analysisDataSelector } from '../../redux/selectors/analysisDataSelector';
import { IState } from '../../redux/@types';
import {
    Box,
    TextField,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Typography,
} from "@mui/material";
import { stringToChecked } from "../../utils/formHandlers";
import { formsActions } from "../../redux/actions/formsActions";
import { usersSelector } from '../../redux/selectors/usersSelector';
import { superUsersSelector } from '../../redux/selectors/superUsersSelector';



interface EmailListInputProps extends RouteComponentProps {
    dataKey: string
    defaultValue: string
    questionText: string
    required: boolean
}

const EmailListInput: React.FC<EmailListInputProps> = ({
    defaultValue,
    dataKey,
    questionText,
    required,
}) => {
    const dispatch = useDispatch();

    const user = useSelector((state: IState) => state.users.user);
    const users = useSelector(usersSelector);
    const superUsers = useSelector(superUsersSelector);
    const initialValue = useSelector(analysisDataSelector(dataKey));
    const [value, setValue] = useState<any>(initialValue || defaultValue);
    const [checked, setChecked] = useState(stringToChecked(value));

    const usersList = user && user.role === 'super' && superUsers || users;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newChecked: { [email: string]: boolean } = {
            ...checked,
            [event.target.name]: event.target.checked,
            ...(user && { [user.email]: true }) // Always include current user
        }
        setChecked(newChecked);
        const emailString = Object.keys(newChecked)
            .filter(string => newChecked[string])
            .join(", ");
        dispatch(formsActions.updateFormResponse(dataKey, emailString, "text_answer", questionText));
    };

    useEffect(() => {
        let newChecked: { [email: string]: boolean } = {
            ...checked,
            ...(user && { [user.email]: true }) // Always include current user
        }
        setChecked(newChecked);
        const emailString = Object.keys(newChecked)
            .filter(string => newChecked[string])
            .join(", ");
        dispatch(formsActions.updateFormResponse(dataKey, emailString, "text_answer", questionText));
    }, []);

    return (
        <Box sx={{ maxHeight: "200px", overflow: "auto", width: "100%" }}>
            <FormGroup>
                {usersList && Object.values(usersList).map(user =>
                    <FormControlLabel key={user.userId} control={
                        <Checkbox
                            checked={!!checked[user.email]}
                            onChange={handleChange}
                            name={user.email}
                        />
                    } label={user.username}/>
                )}
                {Object.keys(usersList).length < 1 && <Typography>No additional users.</Typography>}
            </FormGroup>
        </Box>
    );
}

export default EmailListInput;