import React from "react"
import { RouteComponentProps } from "@reach/router"
import { navigate } from "gatsby";

interface PrivateRouteProps extends RouteComponentProps {
    component: any;
    isLoggedIn: boolean;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({
    component: Component,
    location,
    isLoggedIn,
    ...rest
}) => {
    if (!isLoggedIn) {
        if (typeof window === "undefined") return null;
        navigate('/login');
        return null;
    }

    return <Component {...rest} />;
}

export default PrivateRoute;